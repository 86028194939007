import { useTranslation } from 'react-i18next';
import { Link as NavLink, makeStyles } from '@material-ui/core';
import { ColorButton } from 'components';
import { color } from 'utils/constants';

import LogoSporesCircle from 'assets/icons/spores/logo-spores-circle.png';

const Feedback = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className='App-Feedback'>
      <NavLink href='https://spores.nolt.io'>
        <ColorButton
          hexColor={color.semiBlack}
          className={classes.action}
          startIcon={<img src={LogoSporesCircle} style={{ width: 22 }} />}
        >
          {t('Feedback')}
        </ColorButton>
      </NavLink>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  action: {
    position: 'absolute',
    transform: 'rotate(-90deg) translate(50%, 100%)',
    right: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default Feedback;
