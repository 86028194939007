import React from 'react';
import { useLocation } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import { Container } from '@material-ui/core';
import { InfiniteScroll } from 'components';
import { List } from 'antd';
import { marketService } from 'services/market';
import { parse } from 'query-string';
import { CardCollection } from 'views/Collection/components';

const CollectionListing = () => {
  const location = useLocation();
  const { keywords } = parse(location.search);

  const [dataSearch, setDataSearch] = React.useState({ limit: 15, keywords });

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    ['marketService.fetchSubCollections', dataSearch],
    ({ queryKey, pageParam: page }) => marketService.fetchSubCollections({ ...queryKey[1], page }),
    {
      getNextPageParam: ({ count }, allPages) => {
        if (count !== dataSearch.limit) return undefined;
        return allPages.length + 1;
      },
    },
  );

  React.useEffect(() => {
    setDataSearch((current) => ({
      ...current,
      keywords,
      previousID: undefined,
    }));
  }, [keywords]);

  const dataList = (data.pages ?? []).reduce((previous, data) => previous.concat(data.subCollections), []);

  return (
    <Container>
      <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage}>
        {dataList.length > 0 && (
          <List
            grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
            dataSource={dataList}
            renderItem={(item) => (
              <List.Item>
                <CardCollection item={item} />
              </List.Item>
            )}
          />
        )}
        {isFetching ? (
          <List
            grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
            dataSource={Array.from({ length: 10 }).map(() => ({}))}
            renderItem={(item) => (
              <List.Item>
                <CardCollection.Skeleton />
              </List.Item>
            )}
          />
        ) : (
          dataList.length === 0 && <List dataSource={dataList} />
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default CollectionListing;
