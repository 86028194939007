import { Button, ButtonProps } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { appTheme } from 'containers/Theme';
import { color } from 'utils/constants';

interface ColorButtonProps extends ButtonProps {
  hexColor?: string;
}

const ColorButton = ({ hexColor = color.primary, ...props }: ColorButtonProps) => {
  const theme = createTheme({
    ...appTheme,
    palette: {
      primary: {
        main: hexColor,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button variant='contained' color='primary' disableElevation {...props} />
    </ThemeProvider>
  );
};

export default ColorButton;
