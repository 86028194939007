export const DDMMYYYY = 'DD/MM/YYYY';
export const DDMMYYYY_HHMM = 'DD/MM/YYYY HH:mm';
export const DDMMYYYY__HHMM = 'DD/MM/YYYY, HH:mm';
export const MMDDYYYY__HHMM = 'MM/DD/YYYY, HH:mm';

export const color = {
  primary: '#cfb675',
  primaryLight: '#fff6e0',
  secondary: '#2196f3',
  error: '#f44336',

  white: '#ffffff',
  black: '#242424',
  semiBlack: '#323232',

  link: '#42496E',
  silver: '#d9d9d9',
  alice: '#e4e6e8',
  grey: '#f6f6f6',
  darkgrey: '#767676',
};
