import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField, withStyles } from '@material-ui/core';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';
import { stringify, parse } from 'query-string';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

const TextFieldSearch = withStyles({
  root: {
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
})(TextField);

const SearchBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { keywords = '' } = parse(location.search);
  const { t } = useTranslation();

  const [inputValue, setInputValue] = React.useState(keywords);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const { ...query } = parse(location.search);
      history.push({
        pathname: privateRoute.search.path,
        search: stringify({ ...query, keywords: inputValue }),
      });
    }
  };

  React.useEffect(() => {
    if (location.pathname !== privateRoute.search.path) {
      setInputValue('');
    }
  }, [location.pathname]);

  return (
    <TextFieldSearch
      margin='dense'
      placeholder={t('Search items, creators and collections')}
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)}
      onKeyPress={handleKeyPress}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchOutlinedIcon color='action' />
          </InputAdornment>
        ),
        style: { backgroundColor: color.grey, height: 40 },
        inputProps: { style: { textOverflow: 'ellipsis' } },
      }}
      style={{ width: 420, marginRight: 20 }}
    />
  );
};

export default SearchBar;
