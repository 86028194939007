import { useTranslation } from 'react-i18next';
import { Box, IconButton, Button, Link as NavLink, Typography, CardMedia } from '@material-ui/core';

import ImageRegister from 'assets/images/app-register.png';
import CloseIcon from '@material-ui/icons/Close';

const PopupRegister = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Box style={{ margin: -24 }}>
      <CardMedia image={ImageRegister} style={{ height: 240 }} />
      <Box className='flex-column align-items-center p-24'>
        <Typography variant='h5' gutterBottom>
          {t('Spores - NFT Creator Application')}
        </Typography>

        <Typography variant='body2' color='textSecondary' align='center' className='mb-40'>
          {t('Apply to be a Creator to mint & list your artworks on the Spores NFT marketplace.')}
          <br />
          {t('In the new creative economy, we will rise up together.')}
        </Typography>

        <NavLink href='https://spores.xperx.ai/artist-apply?bemail=%5BEmail%5D'>
          <Button variant='contained' className='gradient-primary'>
            {t('VERIFY PROFILE TO JOIN')}
          </Button>
        </NavLink>

        <IconButton onClick={onClose} style={{ position: 'absolute', top: 12, right: 12, backgroundColor: '#fff2' }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PopupRegister;
