import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Box, IconButton, Button, Typography, TextField, FormGroup } from '@material-ui/core';
import { Loading } from 'components';
import { profileAction } from 'actions/profile';
import { userService } from 'services/user';
import { validator } from 'utils/validator';

import ImageBidEmail from 'assets/images/image-bid-email.png';
import CloseIcon from '@material-ui/icons/Close';

const PopupEmail = ({ onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickUpdate = () => {
    handleSubmit(({ email }) => {
      const formData = new FormData();
      formData.append('email', email);

      setIsLoading(true);
      userService
        .updateProfile(formData)
        .then(() => {
          profileAction.fetchProfile();
          onSuccess();
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  };

  return (
    <Box>
      <div className='justify-content-center mb-12'>
        <img src={ImageBidEmail} />
      </div>
      <Typography variant='h5' align='center' gutterBottom>
        {t('Contact Information')}
      </Typography>

      <Typography variant='body2' color='textSecondary' align='center' className='mb-24'>
        {t(
          `In order to facilitate the Auction process, please provide us with your contact information in case we need to follow up with you regarding your winning bid. Thank you for your support!`,
        )}
      </Typography>

      <FormGroup>
        <Controller
          name='email'
          control={control}
          rules={{
            validate: {
              pattern: (value) => validator.isValidEmail(value) || t('Please provide a valid email address'),
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} label={t('Email')} error={invalid} helperText={error?.message} />
          )}
        />
      </FormGroup>

      <Button
        fullWidth
        variant='contained'
        color='primary'
        startIcon={<Loading visible={isLoading} />}
        onClick={handleClickUpdate}
      >
        {t('CONTINUE')}
      </Button>

      <IconButton onClick={onClose} style={{ position: 'absolute', top: 12, right: 12 }}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default PopupEmail;
