import { Typography, Container } from '@material-ui/core';
import { Paragraph } from 'components';
import { Email } from './links';

const PrivacyPulicy = () => {
  return (
    <Container className='mb-40'>
      <Typography variant='h4'>PRIVACY POLICY</Typography>
      <Typography variant='subtitle1' color='textSecondary' className='mb-24'>
        Last updated June 28, 2021
      </Typography>

      <Typography variant='h6'>Introduction</Typography>
      <ol>
        <Paragraph component='li'>
          Spores Lab, Inc., a Delaware corporation (the "Company"), attaches great importance to the responsible
          handling of personal data of users of its website (hereafter "Users").
        </Paragraph>
        <Paragraph component='li'>
          Spores Lab processes personal data of Users carefully and with due observance of the provisions of the EU
          General Data Protection Regulation (GDPR).
        </Paragraph>
        <Paragraph component='li'>
          In this Privacy Statement, Spores Lab informs Users about the way Spores Lab processes their personal data and
          how they can contrul this process.
        </Paragraph>
      </ol>

      <Typography variant='h5'>Article 1: Explanation of personal data and goal for the processing thereof</Typography>
      <ol>
        <Typography variant='h6'>Personal data</Typography>
        <Paragraph component='li'>
          Spores Lab cullects email addresses and names (hereafter "Personal Data") of individuals who decide to sign up
          for the Spores Lab Newsletter. These are data that can be linked to the identity of an individual person.
        </Paragraph>
        <Paragraph component='li'>
          Spores Lab is at all times responsible for the management of the Personal Data provided by each User and will
          make every effort to protect these Personal Data. In the event of a data breach, Spores Lab will immediately
          inform the User of that event via the email address known to it and make every effort to prevent further
          distribution of these Personal Data.
        </Paragraph>
        <Paragraph component='li'>
          The User has the right at all times to receive the Personal Data provided by him / her to Spores Lab in an
          orderly manner from Spores Lab ("right of access"). On the first request of a User, Spores Lab will forward
          his / her Personal Data to a third party to be designated by that User ("right to data portability") or remove
          it entirely from its system ("right to be forgotten").
        </Paragraph>
        <Paragraph component='li'>
          Any questions that a User may have regarding his Personal Data can be asked to <Email /> These questions will
          be answered within 2 working days at the latest.
        </Paragraph>
        <Typography variant='h6'>Goal</Typography>
        <Paragraph component='li'>
          Spores Lab requires Personal Data from a User to send him or her informational emails for which that User
          signed up.
        </Paragraph>
        <Typography variant='h6'>Permission</Typography>
        <Paragraph component='li'>
          To receive, process and use Personal Data of a User, Spores Lab requires the User’s permission. This
          permission is granted by a User by agreeing to provide his Personal Information in order to receive
          informational emails.
        </Paragraph>
      </ol>

      <Typography variant='h5'>Article 2: Transfer to third parties</Typography>
      <ol>
        <Paragraph component='li'>Spores Lab does not give Personal Data of Users to third parties.</Paragraph>
      </ol>

      <Typography variant='h5'>Article 3: Data security</Typography>
      <ol>
        <Paragraph component='li'>
          Spores Lab makes use of appropriate technical and organizational security procedures, among other things to
          ensure that unauthorized persons do not gain access to Personal Data and to prevent the loss, unlawful use or
          modification of Personal Data.
        </Paragraph>
      </ol>

      <Typography variant='h5'>Article 4: Availability and modification of your data</Typography>
      <ol>
        <Paragraph component='li'>
          Users can request access to their Personal Data at any time. Users may also request that their Personal Data
          be changed or deleted by sending an e-mail to <Email />.
        </Paragraph>
      </ol>

      <Typography variant='h5'>Article 5: Cookies</Typography>
      <ol>
        <Paragraph component='li'>
          Spores Lab uses cookies on its website as mentioned in its Cookie Statement.
        </Paragraph>
      </ol>

      <Typography variant='h5'>Article 6: Change Privacy Statement</Typography>
      <ol>
        <Paragraph component='li'>
          Spores Lab reserves the right to amend this Privacy Statement. Every modification will be announced via
          Spores.app By making use of Spores.app after making changes you agree to a modified Privacy Statement. Keep an
          eye on this page for all changes.
        </Paragraph>
      </ol>
    </Container>
  );
};

export default PrivacyPulicy;
