import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { List } from 'antd';
import { marketService } from 'services/market';
import { privateRoute } from 'routes';
import { CardItem } from 'views/Explore/components';

import IconTopAuction from 'assets/icons/listing/icon-top-auction.png';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const TopAuction = () => {
  const { t } = useTranslation();
  const [dataSearch] = React.useState({ saleType: [1], sortBy: 'endTime', order: 'asc', limit: 10 });

  const { data, isFetching } = useQuery(['marketService.searchItem', dataSearch], ({ queryKey }) =>
    marketService.searchItem({ ...queryKey[1] }),
  );

  return (
    <Container>
      <Box className='justify-content-between align-items-start flex-wrap mb-20'>
        <Typography variant='h4' className='align-items-center'>
          <img src={IconTopAuction} className='mr-12' />
          {t('Live Auctions')}
        </Typography>

        <Link to={privateRoute.exploreAuction.path}>
          <Button endIcon={<ArrowForwardIcon />}>{t('See more')}</Button>
        </Link>
      </Box>

      {!isFetching ? (
        <List
          grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
          dataSource={data.nfts}
          renderItem={(item) => (
            <List.Item>
              <CardItem item={item} />
            </List.Item>
          )}
        />
      ) : (
        <List
          grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
          dataSource={Array.from({ length: 10 }).map(() => ({}))}
          renderItem={(item) => (
            <List.Item>
              <CardItem.Skeleton />
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default TopAuction;
