import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ButtonSelect, ColorButton } from 'components';
import { parse } from 'query-string';
import { color } from 'utils/constants';

import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

const SORT_FILTERS = [
  { id: 1, name: 'Recently added', code: 'createdAt.desc' },
  { id: 2, name: 'Lowest price', code: 'price.asc' },
  { id: 3, name: 'Highest price', code: 'price.desc' },
];

const TopListingSearch = ({ onSearch }) => {
  const location = useLocation();
  const { keywords } = parse(location.search);
  const { t } = useTranslation();
  const { categories, subCategories } = useSelector(({ system }) => system);

  const [category, setCategory] = React.useState({});
  const [subCategory, setSubCategory] = React.useState({});

  const [sortBy, setSortBy] = React.useState(SORT_FILTERS[0].code.split('.')[0]);
  const [order, setOrder] = React.useState(SORT_FILTERS[0].code.split('.')[1]);

  React.useEffect(() => {
    onSearch({
      categoryID: category.id,
      subCategoryID: subCategory.id,
      sortBy,
      order,
      keywords,
    });
  }, [onSearch, category.id, subCategory.id, sortBy, order, keywords]);

  const handleChangeCategory = (category, subCategory = {}) => {
    setCategory(category);
    setSubCategory(subCategory);
  };

  const handleChangeSubCategory = (subCategory) => {
    setSubCategory(subCategory);
  };

  const handleChangeSort = (code) => {
    const [sortBy, order] = code.split('.');
    setSortBy(sortBy);
    setOrder(order);
  };

  return (
    <Box>
      <Box className='flex-row flex-wrap justify-content-end align-items-center mb-8'>
        {[{ name: 'All' }].concat(categories).map((item) => {
          const selected = item.id === category.id;
          return (
            <ColorButton
              key={item.id ?? 0}
              startIcon={item.id ? <CategoryOutlinedIcon /> : null}
              style={{ margin: '2px 0px 2px 12px' }}
              hexColor={selected ? color.black : color.darkgrey}
              variant={selected ? 'contained' : 'outlined'}
              onClick={() => handleChangeCategory(item)}
            >
              {t(item.name)}
            </ColorButton>
          );
        })}
        <ButtonSelect
          style={{ whiteSpace: 'nowrap', marginLeft: 24 }}
          options={SORT_FILTERS.map((item) => (
            <ButtonSelect.Option
              key={item.id}
              selected={item.code === `${sortBy}.${order}`}
              onClick={() => handleChangeSort(item.code)}
            >
              {t(item.name)}
            </ButtonSelect.Option>
          ))}
        >
          {t(SORT_FILTERS.find((item) => item.code === `${sortBy}.${order}`)?.name)}
        </ButtonSelect>
      </Box>
      <Box className='flex-row flex-wrap justify-content-end'>
        {[{ name: 'All' }].concat(subCategories.filter((sub) => sub.categoryId === category.id)).map((sub) => {
          const selected = sub.id === subCategory.id;
          return (
            <ColorButton
              key={sub.id ?? 0}
              size='small'
              style={{ margin: '2px 0px 2px 4px' }}
              hexColor={selected ? color.darkgrey : color.white}
              onClick={() => handleChangeSubCategory(sub)}
            >
              {t(sub.name)}
            </ColorButton>
          );
        })}
      </Box>
    </Box>
  );
};

export default TopListingSearch;
