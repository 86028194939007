import React from 'react';
import { useQuery } from 'react-query';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { List } from 'antd';
import { marketService } from 'services/market';
import { CardItem } from 'views/Explore/components';

const UserItems = ({ params, onUpdateCounter }) => {
  const [dataSearch, setDataSearch] = React.useState({ ...params, page: 1, limit: 15 });

  const { data, isFetching } = useQuery(
    ['marketService.searchItemByUser', dataSearch],
    ({ queryKey }) => marketService.searchItemByUser({ ...queryKey[1] }),
    { keepPreviousData: true },
  );
  const { nfts = [], total = 0 } = data;

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      ...search,
    }));
  }, []);

  React.useEffect(() => {
    onUpdateCounter(total);
  }, [onUpdateCounter, total]);

  return (
    <>
      {isFetching ? (
        <List
          grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
          dataSource={Array.from({ length: dataSearch.limit }).map(() => ({}))}
          renderItem={(item) => (
            <List.Item>
              <CardItem.Skeleton />
            </List.Item>
          )}
        />
      ) : (
        <List
          grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
          dataSource={nfts}
          renderItem={(item) => (
            <List.Item>
              <CardItem item={item} />
            </List.Item>
          )}
        />
      )}
      <Box className='justify-content-center mt-12'>
        <Pagination
          size='large'
          count={Math.ceil(total / dataSearch.limit)}
          page={dataSearch.page}
          onChange={(event, page) => handleChangeSearch({ page })}
        />
      </Box>
    </>
  );
};

export default UserItems;
