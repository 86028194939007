import { Box, Paper, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { Heading } from './Headings';

import ClearAllIcon from '@material-ui/icons/ClearAll';

const Roadmap = () => {
  return (
    <Box>
      <Heading align='center'>Roadmap</Heading>
      <Row gutter={[40, 24]} className='justify-content-center'>
        <Col xl={8} md={16} sm={24}>
          <Paper className='p-24' style={{ height: '100%' }}>
            <Typography variant='h6' className='align-items-center'>
              <ClearAllIcon /> Q3 2021
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              LP staking
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Launch NFT marketplace
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              ERC-1155 experiment
            </Typography>
            <Box height={40} />

            <Typography variant='h6' className='align-items-center'>
              <ClearAllIcon /> Q4 2021
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Integrating BSC ecosystem
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Polygon/Matic layer-2 solution
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Gamestore
            </Typography>
          </Paper>
        </Col>
        <Col xl={8} md={16} sm={24}>
          <Paper className='p-24' style={{ height: '100%' }}>
            <Typography variant='h6' className='align-items-center'>
              <ClearAllIcon /> H1 2022
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Mobile web / Mobile app
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              NFT-DeFi experiments
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Fiat payment solution
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Cardano: experiment and testing
            </Typography>
            <Box height={40} />

            <Typography variant='h6' className='align-items-center'>
              <ClearAllIcon /> H2 2022
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Cardano integration
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Defi applications for NFTs
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              NFT evaluation tools integration
            </Typography>
          </Paper>
        </Col>
        <Col xl={8} md={16} sm={24}>
          <Paper className='p-24' style={{ height: '100%' }}>
            <Typography variant='h6' className='align-items-center'>
              <ClearAllIcon /> H1 2023
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Cross-chain payment solution
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Expanding to more blockchains
            </Typography>
            <Box height={40} />

            <Typography variant='h6' className='align-items-center'>
              <ClearAllIcon /> H2 2023
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Whitelabel solutions
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              SDK for partnership development
            </Typography>
            <Typography style={{ backgroundColor: '#f6f6f6' }} className='align-items-center my-8 p-12 radius-8'>
              Integrating NFT cross-chain solutions
            </Typography>
          </Paper>
        </Col>
      </Row>
    </Box>
  );
};

export default Roadmap;
