import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, Tab, Container } from '@material-ui/core';
import { TopListing } from 'views/Explore/components';
import { parse, stringify } from 'query-string';
import { CollectionListing, UserListing } from './components';

const Search = () => {
  const location = useLocation();
  const { tab, ...query } = parse(location.search);
  const { t } = useTranslation();

  const tabs = [
    { id: 1, code: 'nft', label: t('Collectibles'), component: <TopListing isSearch /> },
    { id: 2, code: 'creator', label: t('Creators'), component: <UserListing /> },
    { id: 3, code: 'collection', label: t('Collections'), component: <CollectionListing /> },
  ];
  const [activeTab, setActiveTab] = React.useState((tabs.find((item) => item.code === tab) ?? tabs[0]).code);

  const handleChangeTab = (_, nextTab) => {
    setActiveTab(nextTab);
    window.history.replaceState(null, null, '?' + stringify({ ...query, tab: nextTab }));
  };

  return (
    <Box>
      <Container>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
      </Container>
      {tabs.map((tab) => (
        <Box key={tab.id} hidden={tab.code !== activeTab} py={2}>
          {tab.component}
        </Box>
      ))}
    </Box>
  );
};

export default Search;
