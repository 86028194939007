import { createStore, combineReducers } from 'redux';
import profile from './profile';
import system from './system';
import coreui from './coreui';

export const store = createStore(
  combineReducers({
    profile,
    system,
    coreui,
  }),
  {},
);
