import { Box, Typography, Link as NavLink, IconButton } from '@material-ui/core';
import { Col, Row } from 'antd';
import { Heading } from './Headings';

import LinkedinFilledIcon from '@ant-design/icons/LinkedinFilled';
import GlobalOutlinedIcon from '@ant-design/icons/GlobalOutlined';

import ProfYangWANGAdvisory from '../people/ProfYangWANG.png';
import RogerLimAdvisory from '../people/RogerLim.png';
import AmericanMcGeeAdvisory from '../people/AmericanMcGee.png';
import MattOrlichAdvisory from '../people/MattOrlich.png';
import DrLarryShiAdvisory from '../people/DrLarryShi.png';

const AdvisoryTeam = () => {
  return (
    <>
      <Heading align='center'>Advisory Team</Heading>
      <Row gutter={[24, 24]} className='justify-content-center'>
        {[
          {
            image: ProfYangWANGAdvisory,
            name: 'Prof. Yang WANG',
            position: 'Scientific Advisor',
            website: 'https://facultyprofiles.ust.hk/profiles.php?profile=yang-wang-yangwang',
          },
          {
            image: RogerLimAdvisory,
            name: 'Roger Lim',
            position: 'Advisor',
            linkedIn: 'https://www.linkedin.com/in/limroger/',
          },
          {
            image: AmericanMcGeeAdvisory,
            name: 'American McGee',
            position: 'Creative Advisor',
            website: 'https://www.americanmcgee.com/about/',
          },
          {
            image: MattOrlichAdvisory,
            name: 'Matt Orlich',
            position: 'Game & Esports Advisor',
            linkedIn: 'https://www.linkedin.com/in/mattorlich/',
          },
          {
            image: DrLarryShiAdvisory,
            name: 'Dr. Larry Shi',
            position: 'Blockchain Advisor',
            website: 'https://uh.edu/nsm/computer-science/people/spotlight/spotlights/2014/larry-shi.php',
          },
        ].map((item, index) => (
          <Col key={index} flex='20%' className='flex-column'>
            <img src={item.image} style={{ maxWidth: 250, borderRadius: 10 }} />
            <Typography variant='h6'>{item.name}</Typography>
            <Box className='justify-content-between align-items-center'>
              <Typography>{item.position}</Typography>
              {item.linkedIn ? (
                <IconButton size='small' component={NavLink} href={item.linkedIn}>
                  <LinkedinFilledIcon />
                </IconButton>
              ) : item.website ? (
                <IconButton size='small' component={NavLink} href={item.website}>
                  <GlobalOutlinedIcon />
                </IconButton>
              ) : null}
            </Box>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AdvisoryTeam;
