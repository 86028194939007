import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Countdown from 'react-countdown';

const CountdownTimer = ({ endTime }) => {
  const classes = useStyles();
  return (
    <Box className='flex-row'>
      <Countdown
        date={endTime * 1000}
        renderer={({ formatted: { days, hours, minutes, seconds } }) => (
          <>
            <Typography className={classes.box}>{days}</Typography>
            <Typography className={classes.box}>{hours}</Typography>
            <Typography className={classes.box}>{minutes}</Typography>
            <Typography className={classes.box}>{seconds}</Typography>
          </>
        )}
      />
    </Box>
  );
};

const Text = ({ endTime, onComplete }) => {
  return <Countdown date={endTime * 1000} onComplete={onComplete} />;
};

const Extra = ({ endTime, onComplete }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className='flex-row'>
      <Countdown
        date={endTime * 1000}
        onComplete={onComplete}
        renderer={({ formatted: { days, hours, minutes, seconds } }) => (
          <>
            <Box className={classes.extra}>
              <Typography variant='h5' className={classes.boxExtra}>
                {days}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('Days')}
              </Typography>
            </Box>
            <Box className={classes.extra}>
              <Typography variant='h5' className={classes.boxExtra}>
                {hours}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('Hours')}
              </Typography>
            </Box>
            <Box className={classes.extra}>
              <Typography variant='h5' className={classes.boxExtra}>
                {minutes}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('Minutes')}
              </Typography>
            </Box>
            <Box className={classes.extra}>
              <Typography variant='h5' className={classes.boxExtra}>
                {seconds}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('Seconds')}
              </Typography>
            </Box>
          </>
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    borderRadius: 4,
    margin: '0px 1px',
    padding: '0px 4px',
    backgroundColor: '#232323',
    color: '#fff',
  },
  extra: {
    width: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxExtra: {
    width: 52,
    height: 40,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#232323',
    color: '#fff',
    marginBottom: 8,
  },
}));

CountdownTimer.Text = Text;
CountdownTimer.Extra = Extra;

export default CountdownTimer;
