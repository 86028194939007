import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Box, Typography, Tabs, Tab, Button, Container, Chip, Hidden } from '@material-ui/core';
import { userService } from 'services/user';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';
import { parse, stringify } from 'query-string';
import { UserAvatar, UserCover, UserItems, UserCollections } from './components';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const Profile = () => {
  const { id: artistAddr } = useParams();
  const { id: userAddr, ...profile } = useSelector(({ profile }) => profile);

  const { data: profileArtist } = useQuery(
    ['userService.getProfileArtist', { user: artistAddr }],
    ({ queryKey }) => userService.getProfileArtist({ ...queryKey[1] }),
    { enabled: !!artistAddr },
  );

  React.useEffect(() => {
    document.title = `${artistAddr ? profileArtist.username || '...' : profile.username} - NFT creator | Spores`;
    return () => (document.title = `Spores - NFT marketplace`);
  }, [artistAddr, profileArtist, profile]);

  return (
    <Artist address={artistAddr ?? userAddr} profile={artistAddr ? profileArtist : profile} isProfile={!artistAddr} />
  );
};

const Artist = ({ address, profile, isProfile }) => {
  const location = useLocation();
  const { tab, ...query } = parse(location.search);
  const { t } = useTranslation();

  const [counter, setCounter] = React.useState({});

  const paramsOwned = React.useMemo(() => ({ ownerAddr: address }), [address]);
  const paramsAuction = React.useMemo(() => ({ ownerAddr: address, saleType: [1] }), [address]);
  const paramsFixed = React.useMemo(() => ({ ownerAddr: address, saleType: [0] }), [address]);
  const paramsCreated = React.useMemo(() => ({ creatorAddr: address }), [address]);
  const paramsCollection = React.useMemo(() => ({ creatorAddr: address }), [address]);

  const onUpdateOwned = React.useCallback((num) => setCounter((current) => ({ ...current, owned: num })), []);
  const onUpdateAuction = React.useCallback((num) => setCounter((current) => ({ ...current, auction: num })), []);
  const onUpdateFixed = React.useCallback((num) => setCounter((current) => ({ ...current, fixed: num })), []);
  const onUpdateCreated = React.useCallback((num) => setCounter((current) => ({ ...current, created: num })), []);
  const onUpdateCollection = React.useCallback((num) => setCounter((current) => ({ ...current, collection: num })), []);

  const tabs = [
    {
      id: 1,
      code: 'owned',
      label: t('Owned'),
      component: <UserItems params={paramsOwned} onUpdateCounter={onUpdateOwned} />,
    },
    {
      id: 2,
      code: 'auction',
      label: t('On auction'),
      component: <UserItems params={paramsAuction} onUpdateCounter={onUpdateAuction} />,
    },
    {
      id: 3,
      code: 'fixed',
      label: t('Fixed price'),
      component: <UserItems params={paramsFixed} onUpdateCounter={onUpdateFixed} />,
    },
    {
      id: 4,
      code: 'created',
      label: t('Created'),
      component: <UserItems params={paramsCreated} onUpdateCounter={onUpdateCreated} />,
    },
    {
      id: 5,
      code: 'collection',
      label: t('Collections'),
      component: <UserCollections onUpdateCounter={onUpdateCollection} params={paramsCollection} />,
    },
  ];
  const [activeTab, setActiveTab] = React.useState((tabs.find((item) => item.code === tab) ?? tabs[0]).code);

  const handleChangeTab = (_, nextTab) => {
    setActiveTab(nextTab);
    window.history.replaceState(null, null, '?' + stringify({ ...query, tab: nextTab }));
  };

  return (
    <Box>
      <UserCover user={profile} editable={isProfile} />
      <Container>
        <Box px={3} className='flex-row align-items-start'>
          <Box margin='auto'>
            <UserAvatar user={profile} editable={isProfile} />
          </Box>
          <Hidden smDown>
            <Box zIndex={1} flex={1}>
              <Box style={{ height: 120, padding: '40px 0px 0px 24px', color: '#fff' }}>
                <Typography component='h1' variant='h4'>
                  {profile.username}
                </Typography>
                <Typography>{profile.address}</Typography>
              </Box>
              <Box className='justify-content-between' style={{ padding: '20px 0px 0px 24px' }}>
                <Typography variant='body2' color='textSecondary' className='linebreak' style={{ maxWidth: 420 }}>
                  {profile.bio}
                </Typography>

                {isProfile && (
                  <Link to={privateRoute.profileUpdate.path}>
                    <Button variant='contained' startIcon={<EditOutlinedIcon />}>
                      {t('Edit Profile')}
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          </Hidden>
        </Box>

        <Box mt={6}>
          <Tabs value={activeTab} onChange={handleChangeTab}>
            {tabs.map((tab) => {
              const selected = tab.code === activeTab;
              return (
                <Tab
                  key={tab.id}
                  value={tab.code}
                  label={
                    <Box className='flex-row align-items-end'>
                      <span className='mr-8'>{tab.label}</span>
                      <Chip
                        size='small'
                        variant='outlined'
                        style={{
                          borderRadius: 4,
                          backgroundColor: selected ? color.primary : undefined,
                        }}
                        label={<Typography variant='body2'>{counter[tab.code] ?? 0}</Typography>}
                      />
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
          {tabs.map((tab) => (
            <Box key={tab.id} hidden={tab.code !== activeTab} py={2}>
              {tab.component}
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
