import { Box } from '@material-ui/core';
import { TopListing } from './components';

const ExploreAuction = () => {
  return (
    <Box py={3}>
      <TopListing isAuction />
    </Box>
  );
};

export default ExploreAuction;
