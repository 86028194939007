import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Typography, Container } from '@material-ui/core';
import { List } from 'antd';
import { marketService } from 'services/market';
import { CardItem } from 'views/Explore/components';

const RelatedItems = ({ nftID, ownerAddr, creatorAddr, subCategoryID }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const [dataSearch] = React.useState({ nftID, address: [ownerAddr, user], creatorAddr, subCategoryID, limit: 10 });

  const { data, isFetching } = useQuery(['marketService.fetchRelatedItem', dataSearch], ({ queryKey }) =>
    marketService.fetchRelatedItem({ ...queryKey[1] }).then((nfts) => ({ nfts })),
  );

  return (
    <Container className='mt-40'>
      <Typography variant='h4' className='mb-20'>
        {t('You may also like')}
      </Typography>
      <List
        grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
        dataSource={data.nfts}
        loading={isFetching}
        renderItem={(item) => (
          <List.Item>
            <CardItem item={item} />
          </List.Item>
        )}
      />
    </Container>
  );
};

export default RelatedItems;
