import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { ColorButton } from 'components';
import { collectionContract } from 'contracts';
import { marketService } from 'services/market';
import { privateRoute } from 'routes';
import { Step, STEP, STATUS } from 'views/Create/components/PopupCreate';

import CloseIcon from '@material-ui/icons/Close';

const PopupRemint = ({ item, onSuccess }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const [createStatus, setCreateStatus] = React.useState(STATUS.IDLE);

  const isLoading = [createStatus].includes(STATUS.LOADING);
  const isTryAgain = [createStatus].includes(STATUS.TRYAGAIN);
  const isCreateError = [createStatus].includes(STATUS.ERROR);
  const isSuccess = [createStatus].includes(STATUS.SUSSESS);

  const onCreate = async (isTryAgain) => {
    try {
      setCreateStatus(isTryAgain ? STATUS.TRYAGAIN : STATUS.LOADING);

      const createItem = await marketService.createItemPending({
        subCollectionID: item.subCollectionID,
        editions: [item.edition],
      });

      await collectionContract(createItem.nftMinterContractAddr)
        .methods.mintBatch(user, createItem.tokenIDs, createItem.tokenURIs, createItem.signature)
        .send({ from: user });

      setCreateStatus(STATUS.SUSSESS);
    } catch {
      setCreateStatus(STATUS.ERROR);
    }
  };

  React.useEffect(() => {
    onCreate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={24} wrap={false}>
      <Col flex={1} className='flex-column justify-content-between'>
        <Box>
          <Box className='justify-content-between align-items-center mb-16'>
            <Typography variant='h5'>{t('Remint NFT')}</Typography>
            <IconButton onClick={onSuccess} disabled={isLoading || isTryAgain}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Step step={STEP.CREATE} status={createStatus} onTryAgain={onCreate} />
        </Box>

        {isSuccess ? (
          <Row gutter={24}>
            <Col style={{ flex: 1 }}>
              <Link to={privateRoute.collectionView.url(id)}>
                <Button fullWidth variant='outlined' style={{ height: 52 }}>
                  {t('Back to Collection')}
                </Button>
              </Link>
            </Col>
            <Col style={{ flex: 1 }}>
              <ColorButton fullWidth onClick={onSuccess} style={{ height: 52 }}>
                {t('Continue')}
              </ColorButton>
            </Col>
          </Row>
        ) : (
          <ColorButton
            fullWidth
            disabled={isLoading || isTryAgain || isCreateError}
            onClick={onSuccess}
            style={{ height: 52 }}
          >
            {isLoading || isTryAgain ? t('PROCESSING') : t('SOME ERRORS HAVE OCCURRED')}
          </ColorButton>
        )}
      </Col>
    </Row>
  );
};

export default PopupRemint;
