import { browserHistory } from 'App';
import { store } from 'reducers';
import { notification } from 'antd';
import { profileAction } from 'actions/profile';
import { t } from 'utils/common';
import { API_URI } from 'env';
import { stringify } from 'query-string';
import { privateRoute } from 'routes';
import axios from 'axios';

const onError = ({ response }) => {
  if (response) {
    const { data, status, statusText } = response;
    if (status === 401 || status === 406) {
      profileAction.signOut();
    } else if (status === 404) {
      browserHistory.replace(privateRoute.home.path);
    } else {
      const { message } = data;
      notification.error({
        key: 'axios',
        message: t(message) ?? `${status} - ${statusText}`,
      });
    }
  } else {
    notification.error({
      key: 'axios',
      message: t('Cannot connect to Server'),
    });
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  const { chain } = store.getState().coreui;
  if (['0x38', '0x61'].includes(chain.chainId)) {
    config.url = config.url.replace('/v1', '/v1/bsc');
  }
  const { isLoggedIn, token } = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers, { Authorization: `Bearer ${token}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const client = axios.create({
  baseURL: API_URI,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
client.interceptors.request.use(beforeRequest);

const clientRaw = axios.create({
  baseURL: API_URI,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});

[client, clientRaw].forEach((client) => {
  client.interceptors.response.use(({ data: { data } }) => data, onError);
});

export { client, clientRaw };
