import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { AppHeader, AppFooter } from 'containers';
import { Feedback } from 'components';
import { systemAction } from 'actions/system';
import { profileAction } from 'actions/profile';
import { systemService } from 'services/system';
import { privateRoute } from 'routes';
import { jwtDecode } from 'utils/common';

const PrivateLayout = () => {
  const { isLoggedIn } = useSelector(({ profile }) => profile);
  const [isReady, setIsReady] = React.useState(false);

  useQuery(['categories'], () => systemService.fetchCategories().then(systemAction.saveCategories));
  useQuery(['subCategories'], () => systemService.fetchSubCategories().then(systemAction.saveSubCategories));
  useQuery(['payments'], () => systemService.fetchPayments().then(systemAction.savePayments));

  React.useEffect(() => {
    window.ethereum?.on('accountsChanged', profileAction.signOut);
  }, []);

  React.useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const payload = jwtDecode(profile.token);
      if (payload.exp - 3600 * 0.1 > Date.now() / 1000) {
        profileAction.signIn(profile);
      }
    } catch {
    } finally {
      setIsReady(true);
    }
  }, []);

  return (
    isReady && (
      <div className='App Private-Layout'>
        <AppHeader />
        <Feedback />
        <div className='App-Body'>
          <Switch>
            {Object.values(privateRoute)
              .filter(({ requiredLogin }) => !requiredLogin || isLoggedIn)
              .map(({ path, component }) => (
                <Route exact key={path} path={path} component={component} />
              ))}
            <Redirect from='/' to={privateRoute.explore.path} />
          </Switch>
        </div>
        <AppFooter />
      </div>
    )
  );
};

export default PrivateLayout;
