import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Avatar, Box, Button, Typography, makeStyles } from '@material-ui/core';
import { marketService } from 'services/market';
import { privateRoute } from 'routes';
import BoxImage from './BoxImage';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const BoxCreator = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery(
    ['marketService.fetchSubCollections', { creatorAddr: item.creator.address }],
    ({ queryKey }) => marketService.fetchSubCollections({ ...queryKey[1] }),
    { enabled: !!item.creator.address },
  );
  const subCollections = data.subCollections ?? [];

  return (
    <Box>
      <BoxImage image={item.creator.avatar} label={t('Creator')} author={item.creator.name} user={item.creator} />
      <Typography color='textSecondary' className='linebreak my-16'>
        {item.creator.bio}
      </Typography>
      {subCollections.length > 0 && (
        <>
          <Typography variant='h6' gutterBottom>
            {t('*creator*s collections', { creator: item.creator.name })}
          </Typography>
          <Box className='align-items-center'>
            <Box className={classes.collection}>
              {subCollections.map((sub) => (
                <Avatar
                  key={sub.id}
                  src={sub.image}
                  title={sub.name}
                  component={Link}
                  to={privateRoute.collectionView.url(sub.id)}
                  style={{ width: 52, height: 52, marginRight: 12 }}
                />
              ))}
            </Box>
            {subCollections.length > 5 && (
              <Link to={privateRoute.artist.url(item.creator.address) + `?tab=collection`} title={t('View more')}>
                <Button variant='outlined' className={classes.forward}>
                  <ArrowForwardIcon style={{ fontSize: 20 }} />
                </Button>
              </Link>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  collection: {
    width: (52 + 12) * 5,
    display: 'flex',
    overflowX: 'hidden',
  },
  forward: {
    width: 40,
    minWidth: 40,
    borderRadius: 20,
  },
}));

export default BoxCreator;
