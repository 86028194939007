import { Box, Typography, Link as NavLink, IconButton } from '@material-ui/core';
import { Col, Row } from 'antd';
import { Heading } from './Headings';

import LinkedinFilledIcon from '@ant-design/icons/LinkedinFilled';

import DucLuuFounding from '../people/DucLuu.png';
import EricHungNguyenFounding from '../people/EricHungNguyen.png';
import RubyNguyenFounding from '../people/RubyNguyen.png';
import PavenDoFounding from '../people/PavenDo.png';
import AllenVDamFounding from '../people/AllenVDam.png';
import JeffreyLeeFounding from '../people/JeffreyLee.png';

const FoundingTeam = () => {
  return (
    <>
      <Heading align='center'>Founding Team</Heading>
      <Row gutter={[24, 24]} className='justify-content-center mb-12'>
        {[
          {
            image: DucLuuFounding,
            name: 'Duc Luu',
            position: 'Chairman, Co-founder',
            linkedIn: 'https://www.linkedin.com/in/duc-luu/',
          },
          {
            image: EricHungNguyenFounding,
            name: 'Eric Hung Nguyen',
            position: 'CEO, Co-founder',
            linkedIn: 'https://www.linkedin.com/in/hungngocnguyen/',
          },
          {
            image: RubyNguyenFounding,
            name: 'Ruby Nguyen',
            position: 'COO, Chief Operations Officer',
            linkedIn: 'https://www.linkedin.com/in/runguyen/',
          },
        ].map((item, index) => (
          <Col key={index} flex='20%' className='flex-column'>
            <img src={item.image} style={{ maxWidth: 250, borderRadius: 10 }} />
            <Typography variant='h6'>{item.name}</Typography>
            <Box className='justify-content-between align-items-center'>
              <Typography>{item.position}</Typography>
              {item.linkedIn ? (
                <IconButton size='small' component={NavLink} href={item.linkedIn}>
                  <LinkedinFilledIcon />
                </IconButton>
              ) : null}
            </Box>
          </Col>
        ))}
      </Row>
      <Row gutter={[24, 24]} className='justify-content-center'>
        {[
          {
            image: PavenDoFounding,
            name: 'Paven Do',
            position: 'CTO, Co-founder',
            linkedIn: 'https://www.linkedin.com/in/paven-do/',
          },
          {
            image: AllenVDamFounding,
            name: 'Allen V. Dam',
            position: 'CCO, Chief Content Officer',
            linkedIn: 'https://www.linkedin.com/in/allen-v-dam-66b7bb1/',
          },
          {
            image: JeffreyLeeFounding,
            name: 'Jeffrey Lee',
            position: 'Chief Art Curator',
            linkedIn: 'https://www.linkedin.com/in/jeffreylee888/',
          },
        ].map((item, index) => (
          <Col key={index} flex='20%' className='flex-column'>
            <img src={item.image} style={{ maxWidth: 250, borderRadius: 10 }} />
            <Typography variant='h6'>{item.name}</Typography>
            <Box className='justify-content-between align-items-center'>
              <Typography>{item.position}</Typography>
              {item.linkedIn ? (
                <IconButton size='small' component={NavLink} href={item.linkedIn}>
                  <LinkedinFilledIcon />
                </IconButton>
              ) : null}
            </Box>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default FoundingTeam;
