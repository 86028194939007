import { Link } from 'react-router-dom';
import { Avatar, Typography, Box } from '@material-ui/core';
import { privateRoute } from 'routes';

const BoxImage = ({ reverse, image, label, author, size = 52, user, collection }) => {
  const isLink = user || collection?.id;
  const artistUrl = privateRoute.artist.url(user?.address);
  const collectionUrl = privateRoute.collectionView.url(collection?.id);
  const path = user ? artistUrl : collection?.id ? collectionUrl : undefined;

  return (
    <Box display='flex' alignItems='center'>
      {image && (
        <Box component={isLink ? Link : 'div'} to={path} mr={1.5}>
          <Avatar src={image} style={{ width: size, height: size }} />
        </Box>
      )}
      <Box display='flex' style={{ flex: 1, flexDirection: reverse ? 'column-reverse' : 'column' }}>
        <Typography variant='body2' color='textSecondary'>
          {label}
        </Typography>
        <Typography variant='subtitle1'>{author}</Typography>
      </Box>
    </Box>
  );
};

export default BoxImage;
