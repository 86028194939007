import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Dialog, Typography } from '@material-ui/core';
import { ColorButton, CountdownTimer } from 'components';
import { coreuiAction } from 'actions/coreui';
import { profileAction } from 'actions/profile';
import { marketService } from 'services/market';
import { color } from 'utils/constants';
import { queryClient } from 'config';
import { privateRoute } from 'routes';
import { PopupConfirm, PopupBuy, PopupBid, PopupEmail } from 'views/Create/components';

const CustomButton = (props) => <ColorButton fullWidth size='large' {...props} />;

const Boxer = ({ timer, price, children }) => (
  <Box>
    <Box className='flex-row justify-content-between mb-12'>
      <Box>{timer}</Box>
      <Box style={{ textAlign: 'right' }}>{price}</Box>
    </Box>
    {children}
  </Box>
);

const Price = ({ label, saleInfo }) => (
  <Box>
    <Typography variant='subtitle2' color='textSecondary'>
      {label}
    </Typography>
    <Typography variant='h5'>
      {saleInfo.price ?? saleInfo.offerPrice} {saleInfo.paymentToken?.symbol}
    </Typography>
  </Box>
);

const BoxPrice = ({ item }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);
  const { isLoggedIn, email } = useSelector(({ profile }) => profile);

  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);
  const [isOpenBuy, setIsOpenBuy] = React.useState(false);
  const [isOpenBid, setIsOpenBid] = React.useState(false);
  const [isOpenEmail, setIsOpenEmail] = React.useState(false);

  const condition = React.useCallback(
    (props) => {
      const keys = Object.keys(props).filter((key) => props[key] !== undefined);
      return keys.reduce((answer, key) => answer && item[key] === Boolean(props[key]), true);
    },
    [item],
  );

  const requiredNetwork = (callback) => {
    if (chain.chainId !== window.ethereum?.chainId) {
      coreuiAction.updateNetwork({ isOpenSwitch: true });
    } else {
      callback();
    }
  };

  const onRefresh = () => queryClient.invalidateQueries(['marketService.getItem', { id }]);
  const onRefreshBids = () => queryClient.invalidateQueries(['marketService.fetchHistoryBids']);
  const onRefreshSales = () => queryClient.invalidateQueries(['marketService.fetchHistorySales']);

  const Timer = ({ label, endTime }) => (
    <Box>
      <Typography variant='subtitle2' color='textSecondary'>
        {label}
      </Typography>
      <Typography variant='h5'>
        <CountdownTimer.Text
          endTime={endTime}
          onComplete={() => {
            onRefresh();
            onRefreshBids();
          }}
        />
      </Typography>
    </Box>
  );

  const CancelButton = () => (
    <CustomButton
      hexColor={color.semiBlack}
      variant='outlined'
      style={{ border: `2px solid ${color.alice}` }}
      onClick={() => setIsOpenConfirm(true)}
    >
      {t('CANCEL')}
    </CustomButton>
  );

  const ActionButton = ({ children }) =>
    item.isErc1155 ? (
      <CustomButton disabled>{t('ERC-1155 is coming soon')}</CustomButton>
    ) : isLoggedIn ? (
      children
    ) : (
      <CustomButton onClick={() => profileAction.connect()}>{t('CONNECT WALLET')}</CustomButton>
    );

  React.useEffect(() => {
    if (!isLoggedIn || !item.isListedOnMarket) {
      setIsOpenConfirm(false);
      setIsOpenBuy(false);
      setIsOpenBid(false);
    }
  }, [isLoggedIn, item]);

  return (
    <Box>
      {condition({
        isUserOwner: 1,
        isListedOnMarket: 0,
      }) && (
        <CustomButton onClick={() => history.push(privateRoute.createSale.url(item.id))}>
          {t('PUT ON MARKET')}
        </CustomButton>
      )}

      {condition({
        isUserOwner: 1,
        isListedOnMarket: 1,
        isFixedPrice: 1,
      }) && (
        <Boxer price={<Price label={t('Price')} saleInfo={item.saleInfo} />}>
          <CancelButton />
        </Boxer>
      )}

      {condition({
        isUserOwner: 1,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionStarted: 0,
      }) && (
        <Boxer
          timer={<Timer label={t('Auction starts in')} endTime={item.saleInfo.startTime} />}
          price={<Price label={t('Price')} saleInfo={item.saleInfo} />}
        >
          <CancelButton />
        </Boxer>
      )}

      {condition({
        isUserOwner: 1,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionFinished: 1,
      }) && <CustomButton disabled>{t('AUCTION HAS ENDED')}</CustomButton>}

      {condition({
        isUserOwner: 1,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionStarted: 1,
        isAuctionFinished: 0,
      }) && (
        <Boxer
          timer={<Timer label={t('Auction ends in')} endTime={item.saleInfo.endTime} />}
          price={<Price label={t('Current bid')} saleInfo={item.highestBid} />}
        >
          <CustomButton disabled>{t('AUCTION IS RUNNING')}</CustomButton>
        </Boxer>
      )}

      {condition({
        isUserOwner: 0,
        isListedOnMarket: 0,
      }) && <CustomButton disabled>{t('NOT FOR SALE')}</CustomButton>}

      {condition({
        isUserOwner: 0,
        isListedOnMarket: 1,
        isFixedPrice: 1,
      }) && (
        <Boxer price={<Price label={t('Price')} saleInfo={item.saleInfo} />}>
          <ActionButton>
            <CustomButton onClick={() => requiredNetwork(() => setIsOpenBuy(true))}>{t('BUY NOW')}</CustomButton>
          </ActionButton>
        </Boxer>
      )}

      {condition({
        isUserOwner: 0,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionStarted: 0,
      }) && (
        <Boxer price={<Price label={t('Price')} saleInfo={item.saleInfo} />}>
          <Box className='flex-column align-items-center p-12' style={{ backgroundColor: color.grey }}>
            <Typography variant='subtitle1' color='textSecondary' className='mb-24'>
              {t('AUCTION STARTS IN')}
            </Typography>
            <CountdownTimer.Extra endTime={item.saleInfo.startTime} onComplete={onRefresh} />
          </Box>
        </Boxer>
      )}

      {condition({
        isUserOwner: 0,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionFinished: 1,
        isUserHighestBid: 0,
      }) && (
        <Boxer
          timer={<Timer label={t('Auction has ended')} endTime={item.saleInfo.endTime} />}
          price={<Price label={item.isAuctionBids ? t('Highest bid') : t('Price')} saleInfo={item.highestBid} />}
        >
          <ActionButton>
            <CustomButton disabled>{t('AUCTION HAS ENDED')}</CustomButton>
          </ActionButton>
        </Boxer>
      )}

      {condition({
        isUserOwner: 0,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionFinished: 1,
        isUserHighestBid: 1,
      }) && (
        <Boxer
          timer={
            <Box>
              <Typography variant='subtitle2' color='textSecondary'>
                {t('Auction has ended')}
              </Typography>
              <Typography variant='h5' color='secondary'>
                {t('You placed the highest bid')}
              </Typography>
            </Box>
          }
          price={<Price label={t('Your bid')} saleInfo={item.highestBid} />}
        >
          <CustomButton onClick={() => requiredNetwork(() => setIsOpenBuy(true))}>{t('CONFIRM PURCHASE')}</CustomButton>
        </Boxer>
      )}

      {condition({
        isUserOwner: 0,
        isListedOnMarket: 1,
        isAuction: 1,
        isAuctionStarted: 1,
        isAuctionFinished: 0,
      }) && (
        <Boxer
          timer={<Timer label={t('Auction ends in')} endTime={item.saleInfo.endTime} />}
          price={<Price label={item.isAuctionBids ? t('Current bid') : t('Price')} saleInfo={item.highestBid} />}
        >
          <ActionButton>
            <CustomButton
              onClick={() => requiredNetwork(email ? () => setIsOpenBid(true) : () => setIsOpenEmail(true))}
            >
              {t('BID NOW')}
            </CustomButton>
          </ActionButton>
        </Boxer>
      )}

      <Dialog open={isOpenConfirm}>
        <PopupConfirm
          content={t('Are you sure you want to cancel listing this item?')}
          onConfirm={() => marketService.removeSale({ id: item.saleInfo.id })}
          onSuccess={() => {
            setIsOpenConfirm(false);
            onRefresh();
            onRefreshSales();
          }}
          onClose={() => setIsOpenConfirm(false)}
        />
      </Dialog>

      <Dialog open={isOpenBuy} maxWidth='md'>
        <PopupBuy
          isAuction={item.isAuction}
          item={item}
          onSuccess={() => {
            setIsOpenBuy(false);
            onRefresh();
            onRefreshSales();
          }}
          onClose={() => setIsOpenBuy(false)}
        />
      </Dialog>

      <Dialog open={isOpenBid} maxWidth='md'>
        <PopupBid
          item={item}
          onSuccess={() => {
            setIsOpenBid(false);
            onRefresh();
            onRefreshBids();
          }}
          onClose={() => setIsOpenBid(false)}
        />
      </Dialog>

      <Dialog open={isOpenEmail} maxWidth='xs'>
        <PopupEmail
          onSuccess={() => {
            setIsOpenEmail(false);
            setIsOpenBid(true);
          }}
          onClose={() => setIsOpenEmail(false)}
        />
      </Dialog>
    </Box>
  );
};

export default BoxPrice;
