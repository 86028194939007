import { Box } from '@material-ui/core';
import { TopSeller, TopAuction, TopListing } from './components';

const Explore = () => {
  return (
    <Box>
      {/* <Box position='absolute'>
        <h1>Spores | One of the leading NFT platforms</h1>
        <h6>A cross-chain DeFi-powered NFT marketplace defining decentralized pop culture</h6>
      </Box>
      <Box mt={-3}>
        <TopBanner />
      </Box> */}
      <Box pt={3} pb={4}>
        <TopSeller />
      </Box>
      <Box py={4}>
        <TopAuction />
      </Box>
      <Box pb={3} pt={4}>
        <TopListing isFixedPrice />
      </Box>
    </Box>
  );
};

export default Explore;
