import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Box, Typography, Container } from '@material-ui/core';
import { InfiniteScroll, ColorButton } from 'components';
import { List } from 'antd';
import { marketService } from 'services/market';
import { CardItem } from 'views/Explore/components';
import TopListingSearch from './TopListingSearch';

import IconTopAuction from 'assets/icons/listing/icon-top-auction.png';
import IconTopListing from 'assets/icons/listing/icon-top-listing.png';
import IconTopEmpty from 'assets/icons/listing/icon-top-empty.png';

const TopListing = ({ isAuction, isFixedPrice, isSearch }) => {
  const { t } = useTranslation();
  const [isLoadMore, setIsLoadMore] = React.useState(false);
  const [dataSearch, setDataSearch] = React.useState({
    limit: 15,
    saleType: isAuction ? [1] : isFixedPrice ? [0] : undefined,
  });

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    ['marketService.searchItem', dataSearch],
    ({ queryKey, pageParam: previousID }) => marketService.searchItem({ ...queryKey[1], previousID }),
    {
      getNextPageParam: ({ count, nfts }) => {
        if (count !== dataSearch.limit) return undefined;
        return nfts[count - 1].id;
      },
      enabled: !!dataSearch.sortBy,
      staleTime: 0,
    },
  );

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      ...search,
      previousID: undefined,
    }));
  }, []);

  const dataList = (data.pages ?? []).reduce((previous, data) => previous.concat(data.nfts), []);

  return (
    <Container>
      <Box hidden={isSearch} className='flex-wrap justify-content-between align-items-start mb-20'>
        {isAuction ? (
          <Typography variant='h4' gutterBottom className='align-items-center'>
            <img src={IconTopAuction} className='mr-12' />
            {t('Live Auctions')}
          </Typography>
        ) : (
          <Typography variant='h4' gutterBottom className='align-items-center'>
            <img src={IconTopListing} className='mr-12' />
            {t('Explore')}
          </Typography>
        )}
        <TopListingSearch onSearch={handleChangeSearch} />
      </Box>
      <InfiniteScroll hasMore={isLoadMore && hasNextPage} loadMore={fetchNextPage}>
        {dataList.length > 0 && (
          <List
            grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
            dataSource={dataList}
            renderItem={(item) => (
              <List.Item>
                <CardItem item={item} />
              </List.Item>
            )}
          />
        )}
        {isFetching ? (
          <List
            style={{ marginTop: 8 }}
            grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
            dataSource={Array.from({ length: 10 }).map(() => ({}))}
            renderItem={(item) => (
              <List.Item>
                <CardItem.Skeleton />
              </List.Item>
            )}
          />
        ) : (
          dataList.length === 0 && (
            <Box className='flex-column flex-center py-20'>
              <img src={IconTopEmpty} style={{ borderBottomRightRadius: '33%' }} />
              <Typography variant='h5' color='textSecondary' align='center'>
                {t('There are no NFTs listed')}
              </Typography>
            </Box>
          )
        )}
      </InfiniteScroll>
      <Box mt={3} mb={6} className='justify-content-center' style={{ opacity: hasNextPage && !isLoadMore ? 1 : 0 }}>
        <ColorButton
          style={{ width: 180, background: 'linear-gradient(180deg, #d9a82a 0%, #ebddb7 100%)' }}
          onClick={() => setIsLoadMore(true)}
        >
          {t('LOAD MORE')}
        </ColorButton>
      </Box>
    </Container>
  );
};

export default TopListing;
