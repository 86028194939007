import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { InputNumberFormat } from 'components';

const Amount = ({ control, type }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={24}>
      <Col style={{ flex: 1 }}>
        <Typography variant='subtitle1'>{t('Batch minting')}</Typography>
        <Typography color='textSecondary'>{type}</Typography>
      </Col>
      <Col style={{ flex: 1 }}>
        <Controller
          name='amount'
          defaultValue={1}
          control={control}
          rules={{ validate: (value) => (value && value >= 1) || t('Number of copies is at least 1') }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              required
              fullWidth
              label={t('Number of copies')}
              InputProps={{
                inputComponent: InputNumberFormat,
                inputProps: { decimalScale: 0 },
              }}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default Amount;
