import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Typography, Container } from '@material-ui/core';
import { List } from 'antd';
import { marketService } from 'services/market';
import { CardItem } from 'views/Explore/components';

const RelatedArtist = ({ ignored, artist }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const [dataSearch] = React.useState({ ownerAddr: artist, saleType: [0, 1], limit: 6 });

  const { data, isFetching } = useQuery(['marketService.searchItemByUser', dataSearch], ({ queryKey }) =>
    marketService.searchItemByUser({ ...queryKey[1] }),
  );
  const dataList = (data.nfts ?? []).filter((item) => item.id !== ignored).slice(0, 5);

  return (
    <Container className='mt-40'>
      <Typography variant='h4' className='mb-20'>
        {user === artist ? t('More from your assets') : t('More from this artist')}
      </Typography>
      <List
        grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
        dataSource={dataList}
        loading={isFetching}
        renderItem={(item) => (
          <List.Item>
            <CardItem item={item} />
          </List.Item>
        )}
      />
    </Container>
  );
};

export default RelatedArtist;
