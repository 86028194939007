import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from 'utils/i18n';

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  initImmediate: false,
  useSuspense: false,
  lng: 'en',
});
