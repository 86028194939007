import { Link } from 'react-router-dom';
import { Box, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { privateRoute } from 'routes';

const ViewImage = ({ itemFront = {}, itemBack = {} }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <CardMedia image={itemBack.preview} className={classes.cardBack}>
        <div />
      </CardMedia>
      <CardMedia
        image={itemFront.preview}
        component={Link}
        to={privateRoute.exploreView.url(itemFront.id)}
        className={classes.cardFront}
      >
        <div />
      </CardMedia>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: '5px solid silver',
  },
  cardFront: {
    position: 'absolute',
    right: 28,
    top: 28,
    width: '100%',
    height: '100%',
    border: '1px solid silver',
    backgroundColor: '#3008',
  },
  cardBack: {
    width: '100%',
    height: '100%',
    filter: 'blur(8px)',
  },
}));

export default ViewImage;
