import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar, Box, Dialog, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Upload, message } from 'antd';
import { profileAction } from 'actions/profile';
import { color } from 'utils/constants';
import UserAvatarCrop from './UserAvatarCrop';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

const UserAvatar = ({ user, editable = false, size = 200, border = 6 }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ...profile } = useSelector(({ profile }) => user ?? profile);

  const [file, setFile] = React.useState();
  const [isOpenAvatar, setIsOpenAvatar] = React.useState(false);

  const handleChooseFile = ({ file }) => {
    if (file.size / 1024 / 1024 >= 10) {
      message.error(t('Maximum image size is 10MB'));
    } else if (!file.type.startsWith('image')) {
      message.error(t('File type is not allowed'));
    } else {
      setFile(file);
      setIsOpenAvatar(true);
    }
  };

  const handleSuccess = async () => {
    await profileAction.fetchProfile();
    setIsOpenAvatar(false);
  };

  return (
    <Box position='relative' display='inline-flex'>
      <Avatar
        src={profile.avatar}
        style={{
          width: size,
          height: size,
          border: `${border}px solid #fff`,
          backgroundColor: '#fff',
        }}
      />
      {editable && (
        <Box className={classes.editable}>
          <Upload accept='image/*' showUploadList={false} customRequest={handleChooseFile}>
            <Tooltip title={t('Update Avatar')}>
              <IconButton className={classes.button} style={{ backgroundColor: color.alice }}>
                <CameraAltOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Upload>
          <Dialog open={isOpenAvatar} maxWidth='sm'>
            <UserAvatarCrop file={file} onSuccess={handleSuccess} onCancel={() => setIsOpenAvatar(false)} />
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  editable: {
    position: 'absolute',
    right: 18,
    bottom: 18,
  },
  button: {
    padding: 6,
    border: '2px solid #fff',
  },
}));

export default UserAvatar;
