import { Box } from '@material-ui/core';

const BackgroundColor = ({ color = '#f9f9f9', children }: any) => (
  <>
    <Box style={{ position: 'fixed', inset: 0, backgroundColor: color, zIndex: -1 }} />
    {children}
  </>
);

export default BackgroundColor;
