import { client } from './axios';

const search = (params) => client.get(`/v1/nft/nfts/search`, { params });
const searchUser = (params) => client.get(`/v1/nft/users/artist`, { params });

const searchItem = (params) => client.get(`/v1/nft/nfts`, { params });
const searchItemByUser = (params) => client.get(`/v1/nft/nfts/byUser`, { params });
const getItem = ({ id }) => client.get(`/v1/nft/nfts/${id}/details`);
const createItem = (body) => client.post(`/v1/nft/nfts`, body);
const createItemCollection = (body) => client.post(`/v1/nft/nfts/sub-collection`, body);
const createItemPending = (body) => client.post(`/v1/nft/nfts/sub-collection/pending`, body);

const createSale = (body) => client.post(`/v1/nft/sales`, body);
const removeSale = ({ id }) => client.delete(`/v1/nft/sales/${id}`);

const buy = ({ saleId }) => client.post(`/v1/nft/sales/${saleId}/buy`);
const bid = ({ saleId, ...body }) => client.post(`/v1/nft/sales/${saleId}/bids`, body);
const confirmBid = ({ saleId, bidId }) => client.post(`/v1/nft/sales/${saleId}/bids/${bidId}/buy`);

const fetchHistoryBids = ({ saleId }) => client.get(`/v1/nft/sales/${saleId}/bids`);
const fetchHistorySales = ({ id, ...params }) => client.get(`/v1/nft/nfts/${id}/event-history`, { params });
const fetchTopSellers = (params) => client.get(`/v1/nft/sales/top-sellers`, { params });

const fetchUserHistorySales = (params) => client.get(`/v1/nft/nfts/sales-history`, { params });
const fetchRelatedItem = (params) => client.get(`/v1/nft/nfts/related-nfts`, { params });

const getSubCollection = ({ id }) => client.get(`/v1/nft/sub-collections/${id}`);
const createCollection = (body) => client.post(`/v1/nft/collections`, body);
const fetchSubCollections = (params) => client.get(`/v1/nft/sub-collections`, { params });
const fetchSubCollectionItems = ({ id, ...params }) => client.get(`/v1/nft/sub-collections/${id}/nfts`, { params });

const fetchSubCollectionPendings = ({ id }) => client.get(`/v1/nft/sub-collections/${id}/pending-nfts`);

export const marketService = {
  search,
  searchUser,

  searchItem,
  searchItemByUser,
  getItem,
  createItem,
  createItemCollection,

  createSale,
  removeSale,

  buy,
  bid,
  confirmBid,

  fetchHistoryBids,
  fetchHistorySales,
  fetchTopSellers,

  fetchUserHistorySales,
  fetchRelatedItem,

  getSubCollection,
  createCollection,
  fetchSubCollections,
  fetchSubCollectionItems,

  fetchSubCollectionPendings,
  createItemPending,
};
