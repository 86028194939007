import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppMenu } from 'containers';
import { NetworkBar, SearchBar, ColorButton, LanguageBar } from 'components';
import { Paper, Hidden, IconButton, Dialog, Typography, makeStyles } from '@material-ui/core';
import { List, ListItem, Link as NavLink } from '@material-ui/core';
import { Dropdown } from 'antd';
import { profileAction } from 'actions/profile';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';

import { UserAvatar } from 'views/Profile/components';
import { PopupRegister } from 'views/Create/components';

import LogoUniswap from 'assets/icons/coins/logo-uniswap.png';
import LogoGateIO from 'assets/icons/coins/logo-gateio.png';
import LogoPancakeSwap from 'assets/icons/coins/logo-pancakeswap.png';
import LogoSporesPrimary from 'assets/icons/spores/logo-spores-primary.svg';
import LogoSporesCircle from 'assets/icons/spores/logo-spores-square.png';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoggedIn, username, mintable = false } = useSelector(({ profile }) => profile);

  const [isOpenRegister, setIsOpenRegister] = React.useState(false);

  const handleClickConnect = () => {
    profileAction.connect();
  };

  const handleClickDisconnect = () => {
    profileAction.signOut();
  };

  return (
    <Paper square className='App-Header' color='primary'>
      <Hidden xsDown>
        <div className='align-items-center px-24' style={{ height: 52, color: color.link }}>
          <Hidden mdDown>
            <div className='align-items-center'>
              <Typography variant='body2'>
                Ethereum Contract:{' '}
                <NavLink
                  href='https://etherscan.io/token/0xcbE771323587EA16dACB6016e269D7F08A7ACC4E'
                  title='0xcbE771323587EA16dACB6016e269D7F08A7ACC4E'
                >
                  0xcbE7713235...F08A7ACC4E
                </NavLink>
              </Typography>
              <div style={{ margin: 12, height: 24, borderRight: '2px solid #0002' }} />
              <Typography variant='body2'>
                BSC Contract:{' '}
                <NavLink
                  href='https://bscscan.com/token/0x8357c604c5533fa0053BeAaA1494Da552ceA38f7'
                  title='0x8357c604c5533fa0053BeAaA1494Da552ceA38f7'
                >
                  0x8357c604c5...552ceA38f7
                </NavLink>
              </Typography>
            </div>
          </Hidden>
          <div className='flex-1' />
          <Typography variant='subtitle2' noWrap>
            Get SPO<span className='mx-8'>‒</span>
          </Typography>
          <NavLink
            href='https://app.uniswap.org/#/swap?outputCurrency=0xcbE771323587EA16dACB6016e269D7F08A7ACC4E'
            className='align-items-center mr-24'
          >
            <img src={LogoUniswap} className='mr-4' />
            <Typography variant='subtitle2'>Uniswap</Typography>
          </NavLink>
          <NavLink href='https://www.gate.io/trade/SPO_USDT' className='align-items-center mr-24'>
            <img src={LogoGateIO} className='mr-4' />
            <Typography variant='subtitle2'>Gate.io</Typography>
          </NavLink>
          <NavLink
            href='https://pancakeswap.finance/swap?outputCurrency=0x8357c604c5533fa0053beaaa1494da552cea38f7'
            className='align-items-center mr-24'
          >
            <img src={LogoPancakeSwap} className='mr-4' />
            <Typography variant='subtitle2'>PancakeSwap</Typography>
          </NavLink>
          <NavLink href='https://staking.spores.app'>
            <ColorButton hexColor='#EBECF0' classes={classes} style={{ borderRadius: 20 }}>
              Stake & Earn
            </ColorButton>
          </NavLink>
        </div>
        <div style={{ borderBottom: '1px solid #0002' }} />
      </Hidden>
      <div className='align-items-center px-24' style={{ height: 68 }}>
        <Hidden xsDown>
          <Link to={privateRoute.home.path} className='mr-20'>
            <img src={LogoSporesPrimary} style={{ height: 40 }} />
          </Link>
        </Hidden>
        <Hidden smUp>
          <Link to={privateRoute.home.path} className='mr-20'>
            <img src={LogoSporesCircle} style={{ height: 36 }} />
          </Link>
        </Hidden>

        <div className='mr-12'>
          <NetworkBar visible />
        </div>

        <Hidden mdDown>
          <SearchBar />
        </Hidden>

        <div className='flex-1' />
        <Hidden smDown>
          <AppMenu />
        </Hidden>
        <div className='mx-8'>
          <Hidden smDown>
            <LanguageBar init />
          </Hidden>
        </div>

        {isLoggedIn ? (
          <>
            <Dropdown
              trigger='click'
              placement='bottomRight'
              getPopupContainer={(event) => event.parentNode}
              overlay={
                <div>
                  <List disablePadding component={Paper} style={{ marginTop: 4, minWidth: 160 }}>
                    <ListItem button divider component={Link} to={privateRoute.profile.path}>
                      <Typography variant='subtitle1'>{username}</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={privateRoute.profile.path}>
                      {t('Profile')}
                    </ListItem>
                    <ListItem button component={Link} to={privateRoute.tradeHistory.path}>
                      {t('Trade History')}
                    </ListItem>
                    <ListItem button onClick={handleClickDisconnect}>
                      {t('Disconnect')}
                    </ListItem>
                  </List>
                </div>
              }
            >
              <IconButton size='small' style={{ marginRight: 12 }}>
                <UserAvatar size={40} border={2} />
              </IconButton>
            </Dropdown>
            {mintable ? (
              <Link to={privateRoute.createType.path}>
                <ColorButton classes={classes} startIcon={<CloudUploadOutlinedIcon />}>
                  {t('Create')}
                </ColorButton>
              </Link>
            ) : (
              <>
                <ColorButton
                  classes={classes}
                  startIcon={<CloudUploadOutlinedIcon />}
                  onClick={() => setIsOpenRegister(true)}
                >
                  {t('Create')}
                </ColorButton>
                <Dialog open={isOpenRegister}>
                  <PopupRegister onClose={() => setIsOpenRegister(false)} />
                </Dialog>
              </>
            )}
          </>
        ) : (
          <ColorButton classes={classes} onClick={handleClickConnect}>
            {t('Connect wallet')}
          </ColorButton>
        )}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 'auto',
  },
  label: {
    whiteSpace: 'nowrap',
  },
}));

export default Header;
