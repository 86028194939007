import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { Button, Container, Collapse, Typography, Paper, Box, Dialog, Tooltip } from '@material-ui/core';
import { FormGroup, TextField, MenuItem, Hidden } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { Col, Row } from 'antd';
import { InputNumberFormat, ColorButton } from 'components';
import { coreuiAction } from 'actions/coreui';
import { marketService } from 'services/market';
import { normalizeItem } from 'utils/converter';
import { moment, onFormError } from 'utils/common';
import { color, DDMMYYYY_HHMM } from 'utils/constants';
import { privateRoute } from 'routes';

import { CardItem } from 'views/Explore/components';
import { PopupCreateSale } from 'views/Create/components';

import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import TimelapseOutlinedIcon from '@material-ui/icons/TimelapseOutlined';
import InfoIcon from '@material-ui/icons/Info';

const CREATE_TYPES = [
  {
    id: 1,
    name: 'Fixed Price',
    code: false,
    description: 'Enter price to allow buyers to instantly purchase your NFT',
    icon: <LocalOfferOutlinedIcon />,
  },
  {
    id: 2,
    name: 'Timed Auction',
    code: true,
    description: 'Set a period of time for which buyers can place bids',
    icon: <TimelapseOutlinedIcon />,
  },
];

const CreateView = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);
  const { payments } = useSelector(({ system }) => system);
  const { ...profile } = useSelector(({ profile }) => profile);

  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const { data: apiItem } = useQuery(['marketService.getItem', { id }], ({ queryKey }) =>
    marketService.getItem({ ...queryKey[1] }),
  );
  const nftItem = React.useMemo(() => normalizeItem(apiItem, profile), [apiItem, profile]);

  const [isOpenCreate, setIsOpenCreate] = React.useState(false);
  const [createValues, setCreateValues] = React.useState(false);

  const { isPutOnMarket = true, price, reservedPrice, paymentTokenID } = watch();
  const { isAuction, startTime, endTime } = watch();

  const handleClickCreate = () => {
    if (chain.chainId !== window.ethereum?.chainId) {
      coreuiAction.updateNetwork({ isOpenSwitch: true });
      return;
    }
    handleSubmit((values) => {
      setCreateValues({
        ...{ isPutOnMarket, price, reservedPrice, paymentTokenID },
        ...{ isAuction, startTime, endTime },
        paymentToken: payments.find((next) => next.id === paymentTokenID),
      });
      setIsOpenCreate(true);
    }, onFormError)();
  };

  return (
    <Container maxWidth='md'>
      <Paper className='p-24' elevation={0}>
        <Typography variant='h4' gutterBottom>
          {t('Put on market')}
        </Typography>
        <Row gutter={24}>
          <Col sm={16}>
            <FormGroup>
              <Typography variant='h6' gutterBottom>
                {t('Information')}
              </Typography>
              <TextField InputProps={{ readOnly: true }} label={t('Name')} value={nftItem.name ?? ''} />

              <TextField
                multiline
                InputProps={{ readOnly: true }}
                label={t('Description')}
                value={nftItem.description ?? ''}
              />

              <Row gutter={24}>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label={t('Category')}
                    value={nftItem.category?.name ?? ''}
                  />
                </Col>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label={t('Sub Category')}
                    value={nftItem.subCategory?.name ?? ''}
                  />
                </Col>
              </Row>

              <Box className='justify-content-between align-items-center mb-24'>
                <Typography variant='h6'>{t('Put on Marketplace')}</Typography>
              </Box>
              <MarketForm {...{ control, isPutOnMarket, isAuction, startTime, watch }} />

              <ColorButton size='large' onClick={handleClickCreate} style={{ height: 52 }}>
                {t('PUT ON MARKET')}
              </ColorButton>
              <Dialog open={isOpenCreate} maxWidth='md'>
                <PopupCreateSale
                  item={nftItem}
                  formValues={createValues}
                  onSuccess={(id) => {
                    setIsOpenCreate(false);
                    history.push(privateRoute.exploreView.url(id));
                  }}
                  onClose={() => setIsOpenCreate(false)}
                />
              </Dialog>
            </FormGroup>
          </Col>
          <Col sm={8}>
            <Hidden xsDown>
              <Box style={{ position: 'sticky', top: 60 + 24 }}>
                <Typography variant='h6' gutterBottom>
                  {t('Preview')}
                </Typography>
                <CardItem.Preview {...nftItem} {...{ isPutOnMarket, price, paymentTokenID, isAuction, endTime }} />
              </Box>
            </Hidden>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};

export const MarketForm = ({ control, isPutOnMarket, isAuction, startTime, watch = () => ({}) }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { payments } = useSelector(({ system }) => system);
  const { price } = watch();

  const PaymentTokenSelect = () => (
    <Controller
      name='paymentTokenID'
      defaultValue={payments[0]?.id}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          required
          select
          InputProps={{
            style: { height: 52, minWidth: 100 },
            classes: { notchedOutline: classes.notched },
          }}
        >
          {payments.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.symbol}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );

  return (
    <>
      <Box className='mb-24'>
        <Typography color='textSecondary' gutterBottom>
          {t(CREATE_TYPES.find((item) => item.code === isAuction)?.description)}
        </Typography>
        <Controller
          name='isAuction'
          defaultValue={false}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Row gutter={[24, 12]}>
              {CREATE_TYPES.map((item) => {
                const checked = item.code === value;
                return (
                  <Col span={12} key={item.id}>
                    <Button
                      fullWidth
                      variant='outlined'
                      style={{
                        padding: '24px 16px',
                        borderWidth: 2,
                        borderRadius: 8,
                        borderColor: checked ? color.primary : color.alice,
                        backgroundColor: checked ? color.primaryLight : color.white,
                      }}
                      onClick={() => onChange(item.code)}
                    >
                      <Box className='flex-column align-items-center'>
                        {item.icon}
                        <Typography variant='subtitle1'>{t(item.name)}</Typography>
                      </Box>
                    </Button>
                  </Col>
                );
              })}
            </Row>
          )}
        />
      </Box>

      <Row>
        <Col style={{ flex: 1 }}>
          <Controller
            name='price'
            defaultValue=''
            control={control}
            rules={{
              validate: isPutOnMarket ? { required: (value) => (value && value > 0) || t('Price is required') } : {},
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                required
                fullWidth
                label={isAuction ? t('Initial Price') : t('Price')}
                InputProps={{
                  inputComponent: InputNumberFormat,
                  style: { height: 52, borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                }}
                error={invalid}
                helperText={
                  <>
                    {error?.message}
                    {isAuction && (
                      <Typography component='span' variant='body2' color='textSecondary' className='align-items-start'>
                        {t('Set your starting bid price')}
                        <Tooltip
                          title={t(
                            'This starting bid price will be publicly visible. If you receive a bid above this starting value but below your reserve price, you can accept it any time.',
                          )}
                        >
                          <InfoIcon fontSize='small' color='primary' className='cursor-pointer ml-8' />
                        </Tooltip>
                      </Typography>
                    )}
                  </>
                }
              />
            )}
          />
        </Col>
        <Col>
          <PaymentTokenSelect control={control} />
        </Col>
      </Row>

      <Collapse in={isAuction}>
        <Row gutter={0}>
          <Col style={{ flex: 1 }}>
            <Controller
              name='reservedPrice'
              defaultValue=''
              control={control}
              rules={{
                validate:
                  isPutOnMarket && isAuction
                    ? {
                        required: (value) => value >= 0 || t('Reserved Price is required'),
                        greater: (value) => value > price || t('Reserved Price must be higher than Initial Price'),
                      }
                    : {},
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  label={t('Reserved Price')}
                  InputProps={{
                    inputComponent: InputNumberFormat,
                    style: { height: 52, borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                  }}
                  error={invalid}
                  helperText={
                    <>
                      {error?.message}
                      <Typography component='span' variant='body2' color='textSecondary' className='align-items-start'>
                        {t('The hidden minimum price that you will accept to sell your item')}
                        <Tooltip
                          title={t(
                            "If you don't receive any bids equal to or greater than your reserve, the auction will end without a sale.",
                          )}
                        >
                          <InfoIcon fontSize='small' color='primary' className='cursor-pointer ml-8' />
                        </Tooltip>
                      </Typography>
                    </>
                  }
                />
              )}
            />
          </Col>
          <Col>
            <PaymentTokenSelect control={control} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col style={{ flex: 1 }}>
            <Controller
              name='startTime'
              defaultValue={moment().add(10, 'minutes').startOf('minutes')}
              control={control}
              rules={{
                validate:
                  isPutOnMarket && isAuction
                    ? {
                        isValid: (value) => (value && value.isValid()) || t('Invalid Date Format'),
                        isAfter: (value) =>
                          value.isAfter(moment()) || t('Starting time should be after the current time'),
                      }
                    : {},
              }}
              render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
                <KeyboardDateTimePicker
                  {...field}
                  required
                  fullWidth
                  label={t('Starting Date')}
                  placeholder={DDMMYYYY_HHMM}
                  format={DDMMYYYY_HHMM}
                  minDate={moment()}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Controller
              name='endTime'
              defaultValue={moment().add(10, 'days').startOf('minutes')}
              control={control}
              rules={{
                validate:
                  isPutOnMarket && isAuction
                    ? {
                        isValid: (value) => (value && value.isValid()) || t('Invalid Date Format'),
                        isAfter: (value) =>
                          value.isAfter(startTime) || t('Expiration time should be after the Starting Date'),
                        min: (v) => v.diff(startTime, 'minutes') >= 10 || t('Auction duration is at least 10 minutes'),
                        max: (value) => value.diff(startTime, 'days') < 14 || t('Auction duration is at most 14 days'),
                      }
                    : {},
              }}
              render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
                <KeyboardDateTimePicker
                  {...field}
                  required
                  fullWidth
                  label={t('Expiration Date')}
                  placeholder={DDMMYYYY_HHMM}
                  format={DDMMYYYY_HHMM}
                  minDate={moment()}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  notched: {
    borderLeftColor: 'transparent',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export default CreateView;
