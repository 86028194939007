import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar, Card, CardMedia, Divider, Box, Typography, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { normalizeCollection } from 'utils/converter';
import { privateRoute } from 'routes';

import ErrorIcon from '@material-ui/icons/Error';

const CardCollection = ({ item: apiItem }) => {
  const { t } = useTranslation();
  const { ...profile } = useSelector(({ profile }) => profile);

  const item = React.useMemo(() => normalizeCollection(apiItem, profile), [apiItem, profile]);

  return (
    <Card variant='outlined' className='Card-Container'>
      <CardMedia
        image={item.cover}
        component={Link}
        to={privateRoute.collectionView.url(item.id)}
        style={{ height: 140, position: 'relative' }}
      >
        <Skeleton variant='rect' width='100%' height='100%' />
        {item.isUserCreator && item.isPending && (
          <Tooltip title={t('This collection is missing items')}>
            <ErrorIcon color='error' style={{ position: 'absolute', top: 8, right: 8 }} />
          </Tooltip>
        )}
      </CardMedia>
      <Divider />
      <Box className='flex-column' style={{ padding: '8px 12px 12px' }}>
        <Box className='justify-content-center' style={{ marginTop: -80 }}>
          <Link to={privateRoute.collectionView.url(item.id)}>
            <Avatar src={item.image} style={{ width: 80, height: 80, border: '2px solid #fff' }} />
          </Link>
        </Box>
        <Typography variant='subtitle1' className='text-ellipsis mb-4' title={item.name}>
          <Link to={privateRoute.collectionView.url(item.id)}>{item.name}</Link>
        </Typography>
        <Box className='align-items-center'>
          <Link to={privateRoute.artist.url(item.creator.address)} className='mr-8'>
            <Avatar src={item.creator.avatar} style={{ width: 28, height: 28 }} />
          </Link>
          <Typography variant='body2' color='textSecondary'>
            {item.creator.name}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

const CardSkeleton = () => {
  return (
    <Card variant='outlined' style={{ borderRadius: 8 }}>
      <CardMedia style={{ height: 140 }}>
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Divider />
      <Typography style={{ padding: '8px 12px 4px', marginTop: 4 }}>
        <Skeleton />
      </Typography>
      <Typography style={{ padding: '4px 12px 8px' }}>
        <Skeleton />
      </Typography>
    </Card>
  );
};

CardCollection.Skeleton = CardSkeleton;

export default CardCollection;
