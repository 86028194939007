import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { color } from 'utils/constants';
import QRCode from 'qrcode.react';

import CloseIcon from '@material-ui/icons/Close';

const PopupQrCode = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const url = window.location.href;

  const [isCopy, setIsCopy] = React.useState(false);

  const handleClickCopy = () => {
    try {
      navigator.clipboard.writeText(url);
    } catch {}
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 3000);
  };

  return (
    <>
      <Box className='justify-content-between align-items-center mb-16'>
        <Typography variant='h5'>{t('Share this page')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant='body2' color='textSecondary' className='linebreak mb-12'>
        {t('Scan with your phone’s camera or copy link below')}
      </Typography>
      <Box className='flex-center mb-16'>
        <QRCode value={url} size={240} />
      </Box>
      <Box className={classes.boxLink}>
        <Tooltip
          title={isCopy ? t('Copied!') : t('Copy')}
          placement='top'
          onOpen={() => setIsCopy(false)}
          onClick={handleClickCopy}
        >
          <Typography variant='body2' className='text-ellipsis cursor-pointer'>
            {url}
          </Typography>
        </Tooltip>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  boxLink: {
    padding: 8,
    backgroundColor: color.grey,
    borderRadius: 4,
  },
}));

export default PopupQrCode;
