import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Container, Collapse, Typography, Paper, Box, Dialog, IconButton } from '@material-ui/core';
import { FormGroup, TextField, MenuItem, Hidden } from '@material-ui/core';
import { Col, Row, Image, Upload, Switch, Spin, message } from 'antd';
import { ColorButton } from 'components';
import { coreuiAction } from 'actions/coreui';
import { fileService } from 'services/file';
import { onFormError } from 'utils/common';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';

import { CardItem } from 'views/Explore/components';
import { PopupCreate } from './components';
import { Amount, Collection } from './fields';
import { MarketForm } from './CreateSale';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import CloseIcon from '@material-ui/icons/Close';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

const Create = () => {
  const history = useHistory();
  const { type } = useParams();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);
  const { categories, subCategories, payments } = useSelector(({ system }) => system);

  const { control, handleSubmit, watch, setValue, clearErrors } = useForm({ mode: 'onChange' });

  const [media, setMedia] = React.useState({ isImage: true });
  const [premainLoading, setPremainLoading] = React.useState(false);
  const [previewLoading, setPreviewLoading] = React.useState(false);

  const [isOpenCreate, setIsOpenCreate] = React.useState(false);
  const [createValues, setCreateValues] = React.useState(false);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'categoryID') {
        setValue('subCategoryID', '');
      }
      if (name === 'premain') {
        setValue('preview', null);
        clearErrors('preview');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, clearErrors]);

  const { premain, preview } = watch();
  const { name, description, categoryID, subCategoryID } = watch();
  const { isCollection = type?.includes('contract'), subCollectionID } = watch();
  const { isErc1155 = type?.includes('1155'), amount } = watch();
  const { isPutOnMarket, price, reservedPrice, paymentTokenID } = watch();
  const { isAuction, startTime, endTime } = watch();

  const handleClickCreate = () => {
    if (chain.chainId !== window.ethereum?.chainId) {
      coreuiAction.updateNetwork({ isOpenSwitch: true });
      return;
    }
    handleSubmit((values) => {
      setCreateValues({
        ...{ ...media, premain, preview },
        ...{ name: name.trim(), description: description.trim(), categoryID, subCategoryID },
        ...{ isErc1155, amount },
        ...{ isCollection, subCollectionID },
        ...{ isCreate: true, isPutOnMarket, price, reservedPrice, paymentTokenID },
        ...{ isAuction, startTime, endTime },
        category: categories.find((next) => next.id === categoryID),
        subCategory: subCategories.find((next) => next.id === subCategoryID),
        paymentToken: payments.find((next) => next.id === paymentTokenID),
      });
      setIsOpenCreate(true);
    }, onFormError)();
  };

  return (
    <Container maxWidth='md'>
      <Paper className='p-24' elevation={0}>
        <Box className='flex-row align-items-center mb-12'>
          <IconButton hidden component={Link} to={privateRoute.createType.path}>
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant='h4'>{t('Create collectible')}</Typography>
        </Box>
        <Row gutter={24}>
          <Col sm={16}>
            <Typography variant='h6' gutterBottom>
              {t('Upload file')}
            </Typography>
            <Controller
              name='premain'
              control={control}
              rules={{ required: true }}
              render={({ field: { value: premain, onChange }, fieldState: { invalid } }) => (
                <Upload.Dragger
                  accept='image/*,video/*,.glb'
                  showUploadList={false}
                  customRequest={async ({ file }) => {
                    const isImage = file.type.startsWith('image');
                    const isAudio = file.type.startsWith('audio');
                    const isVideo = file.type.startsWith('video');
                    const isModel = file.name.endsWith('.glb');

                    if (isImage) {
                      if (file.size / 1024 / 1024 > 10) {
                        return message.error(t('Maximum image size is 10MB'));
                      }
                    } else if (isAudio) {
                      if (file.size / 1024 / 1024 > 150) {
                        return message.error(t('Maximum audio size is 150MB'));
                      }
                    } else if (isVideo) {
                      if (file.size / 1024 / 1024 > 150) {
                        return message.error(t('Maximum video size is 150MB'));
                      }
                    } else if (isModel) {
                      if (file.size / 1024 / 1024 > 150) {
                        return message.error(t('Maximum model size is 150MB'));
                      }
                    } else {
                      return message.error(t('File type is not allowed'));
                    }

                    setPremainLoading(true);
                    onChange(await fileService.upload(file, isModel ? { 'Content-Type': 'model/gltf-binary' } : {}));
                    setPremainLoading(false);
                    setMedia({ isImage, isAudio, isVideo, isModel });
                  }}
                  className='mb-24'
                  height={240}
                  style={{ borderColor: invalid ? color.error : undefined }}
                >
                  <Spin spinning={premainLoading}>
                    {media.isVideo ? (
                      <video src={premain} height={200} controls />
                    ) : media.isModel ? (
                      <div className='justify-content-center'>
                        <model-viewer
                          src={premain}
                          auto-rotate='true'
                          autoplay='true'
                          camera-controls='true'
                          ar-status='not-presenting'
                          style={{ height: 200 }}
                        />
                      </div>
                    ) : media.isImage ? (
                      <Image src={premain} height={200} preview={false} />
                    ) : (
                      <Box className='flex-column align-items-center'>
                        <CropOriginalIcon color='action' style={{ fontSize: 60 }} />
                        <Typography variant='h6' color='textSecondary'>
                          {t('Drag & drop file')}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {t('or browse media on your device')}
                        </Typography>

                        <Typography variant='body2' color='textSecondary' className='mt-16'>
                          {t('JPEG, JPG, PNG, GIF, SVG - Maximum size 10MB')}
                          <br />
                          {t('MP4, AVI, WEBM - Maximum size 150MB')}
                          <br />
                          {t('GLB - Maximum size 150MB')}
                        </Typography>
                      </Box>
                    )}
                  </Spin>
                  {premain && (
                    <IconButton
                      onClick={(event) => {
                        setValue('premain', null);
                        event.stopPropagation();
                      }}
                      style={{ position: 'absolute', right: 4, top: 4 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Upload.Dragger>
              )}
            />

            {!media.isImage && (
              <Box className='flex-column mb-24'>
                <Typography variant='h6'>{t('Preview image')}</Typography>
                <Typography variant='body2' color='textSecondary' className='mb-12'>
                  {t(
                    "Because you've uploaded a video, please provide a thumbnail image of your item. Maximum size is 10MB.",
                  )}
                </Typography>

                <Controller
                  name='preview'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value: preview, onChange }, fieldState: { invalid } }) => (
                    <Upload.Dragger
                      accept='image/*'
                      showUploadList={false}
                      customRequest={async ({ file }) => {
                        const isImage = file.type.startsWith('image');
                        if (isImage) {
                          if (file.size / 1024 / 1024 > 10) {
                            return message.error(t('Maximum image size is 10MB'));
                          }
                        } else {
                          return message.error(t('File type is not allowed'));
                        }
                        setPreviewLoading(true);
                        onChange(await fileService.upload(file));
                        setPreviewLoading(false);
                      }}
                      className='flex-center'
                      height={100}
                      style={{ width: 140, borderColor: invalid ? color.error : undefined }}
                    >
                      <Spin spinning={previewLoading}>
                        {preview ? (
                          <Image src={preview} height={80} preview={false} />
                        ) : (
                          <Box className='flex-column flex-center'>
                            <WallpaperIcon />
                          </Box>
                        )}
                      </Spin>
                    </Upload.Dragger>
                  )}
                />
              </Box>
            )}

            <FormGroup>
              <Typography variant='h6' gutterBottom>
                {t('Information')}
              </Typography>
              <Controller
                name='name'
                defaultValue=''
                control={control}
                rules={{
                  validate: {
                    required: (value) => value.trim() !== '' || t('Name cannot be empty'),
                    minLength: (value) => value.trim().length >= 5 || t('Name is at least 5 characters'),
                    maxLength: (value) => value.trim().length < 50 || t('Name is at most 50 characters'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} required label={t('Name')} error={invalid} helperText={error?.message} />
                )}
              />
              <Controller
                name='description'
                defaultValue=''
                control={control}
                rules={{
                  validate: {
                    required: (value) => value.trim() !== '' || t('Description cannot be empty'),
                    minLength: (value) => value.trim().length >= 5 || t('Description is at least 5 characters'),
                    maxLength: (value) => value.trim().length <= 2000 || t('Description is at most 2000 characters'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    required
                    multiline
                    rows={5}
                    label={t('Description')}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />

              <Row gutter={24}>
                <Col style={{ flex: 1 }}>
                  <Controller
                    name='categoryID'
                    defaultValue=''
                    control={control}
                    rules={{ required: t('Please select Category') }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        required
                        select
                        fullWidth
                        label={t('Category')}
                        error={invalid}
                        helperText={error?.message}
                      >
                        {categories.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {t(item.name)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Col>
                <Col style={{ flex: 1 }}>
                  <Controller
                    name='subCategoryID'
                    defaultValue=''
                    control={control}
                    rules={{ required: t('Please select Sub Category') }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        required
                        select
                        fullWidth
                        label={t('Sub Category')}
                        error={invalid}
                        helperText={error?.message}
                        disabled={!categoryID}
                      >
                        {subCategories
                          .filter((item) => item.categoryId === categoryID)
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {t(item.name)}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Col>
              </Row>
              {isErc1155 ? (
                <Amount control={control} type={t('ERC-1151')} />
              ) : isCollection ? (
                <Box className='mb-24'>
                  <Typography variant='h6' gutterBottom>
                    {t('Choose collection')}
                  </Typography>
                  <Collection control={control} />
                  <Box className='mb-24' />
                  <Amount control={control} type={t('ERC-721')} />
                </Box>
              ) : null}

              <Box className='justify-content-between align-items-center mb-24'>
                <Typography variant='h6'>{t('Put on Marketplace')}</Typography>
                <Controller
                  name='isPutOnMarket'
                  defaultValue={false}
                  control={control}
                  render={({ field: { value: checked, onChange } }) => (
                    <Switch
                      checked={checked}
                      onChange={onChange}
                      style={{ backgroundColor: checked ? color.primary : color.silver }}
                    />
                  )}
                />
              </Box>

              <Collapse in={isPutOnMarket}>
                <MarketForm {...{ control, isPutOnMarket, isAuction, startTime, watch }} />
              </Collapse>

              <ColorButton size='large' onClick={handleClickCreate} style={{ height: 52 }}>
                {t('Create')}
              </ColorButton>
              <Dialog open={isOpenCreate} maxWidth='xs'>
                <PopupCreate
                  formValues={createValues}
                  onSuccess={(id) => {
                    setIsOpenCreate(false);
                    history.push(privateRoute.exploreView.url(id));
                  }}
                  onClose={() => setIsOpenCreate(false)}
                />
              </Dialog>
            </FormGroup>
          </Col>
          <Col sm={8}>
            <Hidden xsDown>
              <Box style={{ position: 'sticky', top: 60 + 24 }}>
                <Typography variant='h6' gutterBottom>
                  {t('Preview')}
                </Typography>
                <CardItem.Preview
                  {...media}
                  {...{ premain, preview, name }}
                  {...{ isPutOnMarket, price, paymentTokenID, isAuction, endTime }}
                />
              </Box>
            </Hidden>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};

export default Create;
