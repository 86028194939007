import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { ColorButton } from 'components';
import { collectionBytecode, collectionContract } from 'contracts';
import { marketService } from 'services/market';
import { Step, STEP, STATUS } from 'views/Create/components/PopupCreate';

import CloseIcon from '@material-ui/icons/Close';

const PopupCreateCollection = ({ formValues: values, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const [createStatus, setCreateStatus] = React.useState(STATUS.IDLE);

  const isLoading = [createStatus].includes(STATUS.LOADING);
  const isTryAgain = [createStatus].includes(STATUS.TRYAGAIN);
  const isCreateError = [createStatus].includes(STATUS.ERROR);

  const onCreate = async (isTryAgain) => {
    try {
      setCreateStatus(isTryAgain ? STATUS.TRYAGAIN : STATUS.LOADING);

      const collection = await marketService.createCollection({
        ...values,
        subCollectionName: values.collectionName,
      });

      const deployData = {
        admin: collection.admin,
        registry: collection.sporeRegistry,
        collectionId: collection.collectionID,
        maxEdition: collection.maxEdition,
        requestId: collection.requestID,
        name: values.collectionName,
        symbol: values.symbol,
        signature: collection.signature,
      };
      await collectionContract()
        .deploy({ data: collectionBytecode, arguments: Object.values(deployData) })
        .send({ from: user });

      setCreateStatus(STATUS.SUSSESS);
    } catch {
      setCreateStatus(STATUS.ERROR);
    }
  };

  React.useEffect(() => {
    onCreate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={24} wrap={false}>
      <Col flex={1} className='flex-column justify-content-between'>
        <Box>
          <Box className='justify-content-between align-items-center mb-16'>
            <Typography variant='h5'>{t('Create collection')}</Typography>
            <IconButton onClick={onClose} disabled={isLoading || isTryAgain}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Step
            step={STEP.CREATE}
            status={createStatus}
            onTryAgain={onCreate}
            action={t('Deploy contract')}
            description={t('Deploy code for the new collection smart contract')}
          />
        </Box>

        <ColorButton
          fullWidth
          disabled={isLoading || isTryAgain || isCreateError}
          onClick={() => onSuccess()}
          style={{ height: 52 }}
        >
          {isLoading || isTryAgain ? t('PROCESSING') : isCreateError ? t('SOME ERRORS HAVE OCCURRED') : t('CONTINUE')}
        </ColorButton>
      </Col>
    </Row>
  );
};

export default PopupCreateCollection;
