import { Box, Container } from '@material-ui/core';
import { Headings } from './components';

const AboutUs = () => {
  return (
    <Container>
      <Box>
        <Headings />
      </Box>
    </Container>
  );
};

export default AboutUs;
