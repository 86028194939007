import { store } from 'reducers';
import { ActionType } from 'reducers/profile';
import { notification } from 'antd';
import { userService } from 'services/user';
import { t, validSignature } from 'utils/common';
import Web3 from 'web3';

const requireMetaMask = () => {
  notification.error({
    message: t('Cannot connect MetaMask'),
    description: (
      <a
        href='https://metamask.io/download.html'
        className='MuiTypography-colorSecondary'
        target='_blank'
        rel='noreferrer'
      >
        {t('Install MetaMask')}
      </a>
    ),
    duration: 10,
  });
};

const connect = async (callback) => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    return requireMetaMask();
  }

  const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });

  const { isLoggedIn } = store.getState().profile;
  if (isLoggedIn) {
    if (typeof callback === 'function') callback();
  } else {
    try {
      const { address, nonce } = await userService.nonce({ address: user });
      const message = `Welcome to Spores Marketplace!\n\nClick "Sign" to sign in. No password needed!\n\nI accept the Spores Marketplace TOS: https://spores.app/terms-of-use\n\nWallet address:\n${address}\n\nNonce:\n${nonce}`;

      const signature = validSignature(await window.web3.eth.personal.sign(message, address));
      const login = await userService.login({ address, nonce, signature });

      signIn({ id: address, ...login });
      fetchProfile(address);
      if (typeof callback === 'function') callback();
    } catch {}
  }
};

const signIn = (profile) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data: profile,
  });
};

const fetchProfile = async () => {
  const { isLoggedIn } = store.getState().profile;
  if (isLoggedIn) {
    const profile = await userService.getProfile().catch(() => {});
    store.dispatch({
      type: ActionType.USER_FETCH_PROFILE,
      data: profile,
    });
  }
};

const signOut = () => {
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

export const profileAction = {
  connect,
  fetchProfile,
  signIn,
  signOut,
  requireMetaMask,
};
