import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Paper, List, ListItem, Typography } from '@material-ui/core';
import { Dropdown } from 'antd';
import { coreuiAction, LANGUAGE } from 'actions/coreui';
import { color } from 'utils/constants';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

export const LANGUAGE_BARS = [
  { id: 1, name: 'English', code: 'en' },
  { id: 3, name: '中文(简体)', code: 'zhCN' },
  { id: 2, name: '中文(繁體)', code: 'zhTW' },
];

const LanguageBar = ({ init }) => {
  const { language } = useSelector(({ coreui }) => coreui);
  const { i18n } = useTranslation();

  const handleChangeLanguage = (code) => {
    coreuiAction.updateLanguage(code);
    i18n.changeLanguage(code);
  };

  React.useEffect(() => {
    if (init) {
      setTimeout(() => {
        const language = LANGUAGE_BARS.find((item) => item.code === localStorage.getItem(LANGUAGE)) || LANGUAGE_BARS[0];
        coreuiAction.updateLanguage(language.code);
        i18n.changeLanguage(language.code);
      }, 0);
    }
  }, [init, i18n]);

  const languageChoose = LANGUAGE_BARS.find((item) => item.code === language) || LANGUAGE_BARS[0];

  return (
    <Dropdown
      trigger='click'
      getPopupContainer={(event) => event.parentNode}
      overlay={
        <div>
          <List disablePadding component={Paper}>
            {LANGUAGE_BARS.map((item) => (
              <ListItem
                button
                key={item.id}
                selected={item.code === languageChoose.code}
                onClick={() => handleChangeLanguage(item.code)}
              >
                <Typography>{item.name}</Typography>
              </ListItem>
            ))}
          </List>
        </div>
      }
    >
      <Button endIcon={<ArrowDropDownOutlinedIcon />} style={{ backgroundColor: color.grey, padding: '8px 16px' }}>
        <Typography noWrap>{languageChoose.name}</Typography>
      </Button>
    </Dropdown>
  );
};

export default LanguageBar;
