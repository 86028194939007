export const normalizeItem = (item = {}, { address: user } = {}) => {
  const ownership = item.ownerships?.[0] ?? {};
  const sales = ownership.sales?.filter((item) => item.status === 0) ?? [];
  const isErc1155 = item.nftType === 1;
  const isListedOnMarket = sales.length > 0;
  const isPending = !item.transactionHash;

  const isCollection = item.edition > 0 && item.subCollectionID !== '102328255144947596062966805071452475325';
  const name = isCollection ? `${item.name} #${item.edition}/${item.subCollection.maxEdition}` : item.name;

  const images = item.media?.sort((a, b) => b.type - a.type) ?? [];
  const premain = images[0]?.url;
  const preview = images[1]?.url || premain;

  const isImage = images[0]?.type === 0;
  const isAudio = images[0]?.type === 1;
  const isVideo = images[0]?.type === 2;
  const isModel = images[0]?.type === 3;

  const creator = item.creator || {};
  const owner = ownership.owner || creator;
  const saleInfo = isListedOnMarket ? sales[0] : {};

  const isFixedPrice = saleInfo.saleType === 0;
  const isAuction = saleInfo.saleType === 1;

  const currentTime = Date.now() / 1000;
  const isAuctionStarted = saleInfo.startTime < currentTime;
  const isAuctionFinished = saleInfo.endTime < currentTime;

  const isUserOwner = user ? user === owner.address : false;

  const highestBid = {
    id: saleInfo.highestBidID,
    offerPrice: saleInfo.highestPrice || saleInfo.price,
    creatorAddr: saleInfo.highestBidderAddr,
    paymentToken: saleInfo.paymentToken,
  };
  const isAuctionBids = saleInfo.highestPrice > 0;
  const isUserHighestBid = user ? user === highestBid.creatorAddr : false;

  return {
    ...item,
    ...{ isPending, isErc1155, isListedOnMarket, isFixedPrice },
    ...{ isAuction, isAuctionStarted, isAuctionFinished },

    ...{ name, premain, preview },
    ...{ isImage, isAudio, isVideo, isModel },

    ownership,
    ownershipID: ownership.id,
    creator,
    owner,
    saleInfo,
    collection: normalizeCollection(item.subCollection),

    highestBid,
    ...{ isUserOwner, isAuctionBids, isUserHighestBid },
  };
};

export const normalizeCollection = (item = {}, { address: user } = {}) => {
  if (item?.id) {
    const isPending = item.pending > 0;
    const isUserCreator = user ? user === item.collection?.creatorAddr : false;
    return {
      ...item.collection,
      ...item,
      isPending,
      isUserCreator,
    };
  } else {
    return {
      id: 0,
      image: require('assets/icons/spores/logo-spores-circle.png').default,
      name: 'Spores',
      symbol: '',
    };
  }
};
