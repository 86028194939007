import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Paper, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { privateRoute } from 'routes';

import ImageSingleItem from 'assets/images/image-single-item.png';
import ImageCreateCollection from 'assets/images/image-create-collection.png';
import ImageBatchCollection from 'assets/images/image-batch-collection.png';

const CreateType = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth='md'>
      <Paper className='p-24' elevation={0}>
        <Typography variant='h4' className='mb-24'>
          {t('Choose method')}
        </Typography>

        <Row gutter={[24, 12]}>
          {[
            {
              id: 1,
              name: 'Single',
              description: 'Create collectible',
              image: ImageSingleItem,
              path: privateRoute.create.url('erc-721'),
            },
            {
              id: 2,
              name: 'New Collection',
              description: 'Create new collection',
              image: ImageCreateCollection,
              path: privateRoute.collectionCreate.path,
            },
            {
              id: 3,
              name: 'Collection',
              description: 'Choose an existing collection',
              image: ImageBatchCollection,
              path: privateRoute.create.url('with-contract'),
            },
          ].map((item) => (
            <Col key={item.id} style={{ flex: 1 }}>
              <Link to={item.path}>
                <Button fullWidth style={{ padding: 24, boxShadow: '0px 8px 16px aliceblue, 0px 4px 8px darkgray' }}>
                  <Box className='flex-column align-items-center cursor-pointer'>
                    <img src={item.image} style={{ width: 180 }} />
                    <Typography variant='subtitle1'>{t(item.name)}</Typography>
                    <Typography variant='subtitle2' color='textSecondary'>
                      {t(item.description)}
                    </Typography>
                  </Box>
                </Button>
              </Link>
            </Col>
          ))}
        </Row>
      </Paper>
    </Container>
  );
};

export default CreateType;
