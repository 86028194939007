import React from 'react';
import { Box, Button, Typography, Link as NavLink, makeStyles, Hidden, Dialog } from '@material-ui/core';
import { Col, Row } from 'antd';
import PopupWhitepaper from './PopupWhitepaper';
import Partners from './Partners';
import Roadmap from './Roadmap';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import ACrossChainImage from '../images/a-cross-chain.svg';

import NFTDeFiImage from '../images/nft-defi.svg';
import MultiChainImage from '../images/multi-chain.svg';
import CryptoFiat from '../images/crypto-fiat.svg';

import ArchitectureImage from '../images/architecture.svg';
import TokenomicsImage from '../images/tokenomics.svg';

import TokenMetric1Image from '../images/token-metrics-1.svg';
import TokenMetric2Image from '../images/token-metrics-2.svg';
import TokenMetric3Image from '../images/token-metrics-3.svg';

import EcosystemImage from '../images/ecosystem.svg';

import FoundingTeam from './FoundingTeam';
import AdvisoryTeam from './AdvisoryTeam';

const contractAddress = '0xcbE771323587EA16dACB6016e269D7F08A7ACC4E';

export const Heading = ({ children, ...props }) => (
  <>
    <Hidden xsDown>
      <Typography variant='h2' className='mb-32' {...props}>
        {children}
      </Typography>
    </Hidden>
    <Hidden smUp>
      <Typography variant='h4' className='mb-16' {...props}>
        {children}
      </Typography>
    </Hidden>
  </>
);

const Headings = () => {
  const classes = useStyles();
  const [isOpenWhitepaper, setIsOpenWhitepaper] = React.useState(false);

  return (
    <Box mb={12}>
      <Box mt={6}>
        <Heading>A cross-chain DeFi-powered NFT marketplace defining decentralized pop culture</Heading>
        <Box className='flex-row flex-wrap'>
          <Box mb={3}>
            <Row gutter={[24, 12]}>
              <Col xs={24} md={10} className='align-items-center'>
                <CheckCircleOutlinedIcon color='action' />
                <Typography className='ml-8'>Creator centricity</Typography>
              </Col>
              <Col xs={24} md={14} className='align-items-center'>
                <CheckCircleOutlinedIcon color='action' />
                <Typography className='ml-8'>Community</Typography>
              </Col>
              <Col xs={24} md={10} className='align-items-center'>
                <CheckCircleOutlinedIcon color='action' />
                <Typography className='ml-8'>Borderless</Typography>
              </Col>
              <Col xs={24} md={14} className='align-items-center'>
                <CheckCircleOutlinedIcon color='action' />
                <Typography className='ml-8'>Frictionless</Typography>
              </Col>
            </Row>
            <Box mt={3}>
              <Typography variant='h6'>The official contract address for SPO token:</Typography>
              <Typography
                component={NavLink}
                href={`https://etherscan.io/address/${contractAddress}`}
                color='textSecondary'
              >
                {contractAddress}
              </Typography>
            </Box>
            <Box mt={2} className='Button-Line'>
              <Button
                variant='outlined'
                startIcon={<DescriptionOutlinedIcon />}
                onClick={() => setIsOpenWhitepaper(true)}
              >
                Whitepaper
              </Button>
              <NavLink href={`https://app.uniswap.org/#/swap?outputCurrency=${contractAddress}`}>
                <Button variant='outlined' style={{ width: 120 }}>
                  Get $SPO
                </Button>
              </NavLink>

              <Dialog open={isOpenWhitepaper} maxWidth='lg'>
                <PopupWhitepaper onClose={() => setIsOpenWhitepaper(false)} />
              </Dialog>
            </Box>
          </Box>
          <img src={ACrossChainImage} className={classes.image} />
        </Box>
      </Box>

      <Box mt={12}>
        <Heading align='center'>Key Features</Heading>
        <Row gutter={24}>
          {[
            {
              image: NFTDeFiImage,
              name: 'NFT-DeFi',
              description:
                'NFT fractionalization, fungible tokenization and NFT pool-making, utilizing DeFi lending and farming facilities',
            },
            {
              image: MultiChainImage,
              name: 'Multi-Chain Operability',
              description:
                'Easy minting and managing NFTs on multiple chains, buying/selling & swapping items across many blockchains',
            },
            {
              image: CryptoFiat,
              name: 'Crypto & Fiat Payment',
              description:
                'Cross-chain payment with multiple types of crypto-currency, on ramp and off ramp solutions for many fiat-currencies',
            },
          ].map((item, index) => (
            <Col key={index} xs={24} md={8} className='mb-24'>
              <img src={item.image} style={{ width: '100%' }} />
              <Typography variant='h5' className='mt-12'>
                {item.name}
              </Typography>
              <Typography>{item.description}</Typography>
            </Col>
          ))}
        </Row>
      </Box>

      <Hidden smDown>
        <Box mt={12} style={{ backgroundColor: '#2c2c2c', padding: 60, borderRadius: 30 }}>
          <Typography variant='h2' align='center' className='mb-40' style={{ color: '#fff' }}>
            Architecture
          </Typography>
          <img src={ArchitectureImage} style={{ maxWidth: '90%' }} />
        </Box>
      </Hidden>

      <Box mt={12}>
        <Heading align='center'>Tokenomics</Heading>
        <Row className='flex-row align-items-center'>
          <Col xs={24} md={14} className='justify-content-center'>
            <img src={TokenomicsImage} className={classes.image} />
          </Col>
          <Col xs={24} md={10}>
            <Typography variant='h5' className='mt-12'>
              SPO Token
            </Typography>
            <Typography color='textSecondary'>
              The native token of Spores Platform is designed for community governance and DeFi utilization with
              NFT-creator-centricity and DeFi-community-driven purpose.
            </Typography>
            <Typography variant='subtitle1' className='mt-12'>
              SPO is used to incentivize NFT creators, buyers, agents, and liquidity providers across the ecosystem.
            </Typography>
          </Col>
        </Row>
      </Box>

      <Box mt={12}>
        <Heading align='center'>SPO Token Metrics</Heading>
        <Box mb={3} className='justify-content-center'>
          <img src={TokenMetric1Image} className={classes.image} />
        </Box>
        <Hidden smDown>
          <Box height={40} />
        </Hidden>
        <Box mb={3} className='justify-content-center'>
          <img src={TokenMetric2Image} className={classes.image} />
        </Box>
        <Hidden smDown>
          <Box height={40} />
        </Hidden>
        <Box mb={3} className='justify-content-center'>
          <img src={TokenMetric3Image} className={classes.image} />
        </Box>
        <Hidden smDown>
          <Box height={40} />
        </Hidden>
      </Box>

      <Box mt={12}>
        <Heading align='center'>Ecosystem</Heading>
        <Box className='justify-content-center'>
          <img src={EcosystemImage} className={classes.image} />
        </Box>
      </Box>

      <Box mt={12}>
        <Partners />
      </Box>
      <Box mt={12}>
        <FoundingTeam />
      </Box>
      <Box mt={12}>
        <Roadmap />
      </Box>
      <Box mt={12}>
        <AdvisoryTeam />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '100%',
    margin: 'auto',
  },
}));

export default Headings;
