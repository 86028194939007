import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { color } from 'utils/constants';

export const appTheme = createTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      InputLabelProps: { shrink: true },
      inputProps: { autoSave: 'false' },
    },
    MuiChip: {
      variant: 'outlined',
    },
    MuiTypography: {
      component: 'div',
    },
    MuiInputBase: {
      style: {
        backgroundColor: color.white,
      },
    },
    MuiButton: {
      disableElevation: true,
      style: {
        textTransform: 'none',
      },
    },
    MuiContainer: {
      maxWidth: 'xl',
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiLink: {
      target: '_blank',
    },
  },
  typography: {
    fontFamily: 'Segoe UI Variable',
    button: { fontWeight: 600, textTransform: 'none' },
    h1: { fontWeight: 700 },
    h2: { fontWeight: 700 },
    h3: { fontWeight: 700 },
    h4: { fontWeight: 700 },
    h5: { fontWeight: 700 },
    h6: { fontWeight: 700 },
    subtitle1: { fontWeight: 600, lineHeight: 1.5 },
    subtitle2: { fontWeight: 600, lineHeight: 1.43 },
    overline: { fontWeight: 700, textTransform: 'none', fontSize: '1rem', lineHeight: 1.5 },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.725rem',
      },
    },
    MuiContainer: {
      maxWidthXl: {
        maxWidth: '1440px !important',
      },
      maxWidthMd: {
        maxWidth: '880px !important',
      },
      maxWidthSm: {
        maxWidth: '580px !important',
      },
    },
    MuiButton: {
      sizeLarge: {
        height: 64,
        fontSize: 20,
      },
      sizeSmall: {
        minHeight: 32,
      },
      root: {
        minHeight: 40,
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: 8,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '1rem',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '16px 14px',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: color.black,
      },
      flexContainer: {
        borderBottom: '1px solid #0001',
      },
    },
    MuiTab: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiLink: {
      root: {
        color: color.link,
        '&:hover': {
          color: color.primary,
        },
      },
    },
  },
  palette: {
    primary: {
      main: color.primary,
    },
    secondary: {
      main: color.secondary,
    },
    common: {
      black: color.black,
    },
  },
});

const Theme = ({ children }) => {
  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export default Theme;
