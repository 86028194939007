import { client } from './axios';

const fetchCategories = () => client.get(`/v1/nft/categories`);
const fetchSubCategories = () => client.get(`/v1/nft/sub-categories`);
const fetchPayments = () => client.get(`/v1/nft/payment-tokens`);
const fetchSystems = () => client.get(`/v1/nft/systems`);

export const systemService = {
  fetchCategories,
  fetchSubCategories,
  fetchPayments,
  fetchSystems,
};
