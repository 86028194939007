import { Typography, Container } from '@material-ui/core';
import { Paragraph } from 'components';
import { Email, Website } from './links';

const CookieStatement = () => {
  return (
    <Container className='mb-40'>
      <Typography variant='h4'>COOKIE STATEMENT</Typography>
      <Typography variant='subtitle1' color='textSecondary' className='mb-24'>
        Last updated June 28, 2021
      </Typography>

      <Paragraph>
        This Cookie Statement explains how SPORES LAB INC., a Delaware limited liability company doing business as
        Spores Lab ("Spores", "we", "us", or "our") uses cookies and similar technologies to recognize you when you
        visit our websites at <Website /> (our "Websites"). To learn more about our privacy practices, please read our
        privacy policy.
      </Paragraph>
      <Paragraph>
        This Cookie Statement explains what cookie technologies are and why we use them, as well as your rights to
        control our use of them.
      </Paragraph>

      <Typography variant='h5'>What are cookies?</Typography>
      <Paragraph>
        Cookies are small data files that are placed on your computer or mobile device when you visit a website using
        your browser. Cookies are widely used by website owners in order to make their websites work or to work more
        efficiently, as well as to provide reporting information.
      </Paragraph>
      <Paragraph>
        Cookies set by the website owner are called "first party cookies". Cookies set by parties other than the website
        owner are called "third party cookies". Third party cookies enable third party features or functionality on
        websites (e.g., advertising, interactive content and analytics). The parties that set these third party cookies
        can recognize your browser both when it visits the website in question and also when it visits certain other
        websites.
      </Paragraph>

      <Typography variant='h5'>
        What about other tracking technologies, like device advertising identifiers and tracking pixels?
      </Typography>
      <Paragraph>
        Cookies are not the only way to recognize or track visitors to a website or mobile application. We may use
        other, similar technologies from time to time, like collecting the advertising identifier on your mobile device,
        using local storage on your mobile device, or using tracking pixels (sometimes called "web beacons" or "clear
        gifs"). To explain these technologies:
      </Paragraph>
      <Paragraph>
        Tracking pixels are tiny graphics files that are associated with a unique identifier that enables us to
        recognize when someone has visited our Websites. This allows us, for example, to monitor the traffic patterns of
        users within our Websites and to understand whether you have come to our Websites from an online advertisement
        displayed on a third-party website. In many instances, tracking pixels will also enable cookies to be served in
        order to allow more detailed tracking of website visitors’ behavior, including potentially for advertising
        purposes. However, declining cookies by following the instructions below will prevent this more detailed
        tracking.
      </Paragraph>
      <Paragraph>
        E-mail tracking occurs when we place tracking pixels within emails that we send to you in order to measure the
        success of our e-mail marketing campaigns. These tracking pixels enable us to determine whether our emails have
        been opened by their recipients. Most email programs will offer you the ability to prevent images from loading
        within emails, and if you choose to do this, it will prevent our email tracking pixels from being delivered to
        your device.
      </Paragraph>
      <Paragraph>
        Application local storage refers to when an application other than a web browser uses a small amount of your
        device’s storage to set and retrieve cookies in a similar manner and for the same purposes as your web browser
        does as described above under "What are cookies?".
      </Paragraph>

      <Typography variant='h5'>Why do we use cookies and similar technologies?</Typography>
      <Paragraph>
        We use first-party and third-party cookies, local storage, and session storage for several reasons.
      </Paragraph>
      <Paragraph>
        Some information stored in cookies, local storage, and session storage is required for technical reasons in
        order for our Websites to operate or to provide our Services, and we refer to these as "essential" or "strictly
        necessary".
      </Paragraph>
      <Paragraph>
        To this end we store unique identifiers, like session tokens for authentication purposes in application local
        storage, cookies and session storage that are essential to our Services. We also store information about how
        you’ve used our Services (such as which pages you’ve visited or elements you’ve interacted with), information
        about the device settings you’ve enabled and certain location data in application local storage that are
        essential to our Services.
      </Paragraph>
      <Paragraph>
        We also use cookies and local storage to enhance the performance and functionality of our Services, but in ways
        that are non-essential to their use. However, without our storing this information, certain functionality may
        become unavailable.
      </Paragraph>
      <Paragraph>
        Additionally, we use cookies and local storage to collect information that is used either in aggregate form to
        help us understand how our Services are being used, or to help us improve our Services.
      </Paragraph>

      <Typography variant='h5'>How can I control cookies?</Typography>
      <Paragraph>You have the right to decide whether to accept or reject cookies.</Paragraph>
      <Paragraph>
        You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you
        may still use our Websites though your access to some functionality and areas of our website may be restricted.
        As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you
        should visit your browser’s help menu for more information.
      </Paragraph>

      <Typography variant='h5'>How often will you update this Cookie Statement?</Typography>
      <Paragraph>
        The date at the top of this Cookie Statement indicates when it was last updated. We may update this Cookie
        Statement from time to time in order to reflect, for example, changes to the cookies we use or for other
        operational, legal or regulatory reasons. Please therefore re-visit this Cookie Statement regularly to stay
        informed about our use of cookies and related technologies.
      </Paragraph>

      <Typography variant='h5'>Where can I get further information?</Typography>
      <Paragraph>
        If you have any questions about our use of cookies or other technologies, please email us at <Email />.
      </Paragraph>
    </Container>
  );
};

export default CookieStatement;
