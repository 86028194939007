import { notification, message } from 'antd';
import { Translation } from 'react-i18next';

export { default as jwtDecode } from 'jwt-decode';
export { default as moment } from 'moment';

export const onFormError = () => {
  message.error(t('Please check again form values'));
};

export const onCatchError = (error) => {
  console.log(error);
  if (error.code && error.code !== 4001) {
    notification.error({ message: t('Unexpected errors occurred') });
  }
};

export const formatPrice = (price) =>
  Number(price).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 6,
    useGrouping: false,
  });

export const t = (message) => <Translation>{(t) => t(message)}</Translation>;

export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const shorten = (hash, head = 6, tail = 6) => {
  if (typeof hash !== 'string') return hash;
  if (hash.length !== 42) return hash;
  return hash.slice(0, head) + '...' + hash.slice(42 - tail);
};

export const validSignature = (signature) => {
  let suffix = signature.slice(-2);
  if (suffix !== '1b' && suffix !== '1c') {
    suffix = (Number(signature.slice(-2)) + 27).toString(16);
  }
  return signature.slice(0, signature.length - 2) + suffix;
};
