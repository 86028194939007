import { store } from 'reducers';
import { ActionType } from 'reducers/coreui';

export const DARKMODE = 'darkmode';
export const LANGUAGE = 'language';

const updateDarkmode = (darkmode) => {
  store.dispatch({
    type: ActionType.GET_DARKMODE,
    data: darkmode,
  });
  localStorage.setItem(DARKMODE, darkmode);
};

const updateLanguage = (language) => {
  store.dispatch({
    type: ActionType.GET_LANGUAGE,
    data: language,
  });
  localStorage.setItem(LANGUAGE, language);
};

const updateChain = (chain) => {
  store.dispatch({
    type: ActionType.GET_CHAIN,
    data: chain,
  });
};

const updateNetwork = (network) => {
  store.dispatch({
    type: ActionType.GET_NETWORK,
    data: network,
  });
};

export const coreuiAction = {
  updateDarkmode,
  updateLanguage,
  updateChain,
  updateNetwork,
};
