import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Button, Container, Typography, Paper, Box, Dialog } from '@material-ui/core';
import { FormGroup, TextField, Hidden } from '@material-ui/core';
import { Col, Row } from 'antd';
import { ColorButton } from 'components';
import { coreuiAction } from 'actions/coreui';
import { marketService } from 'services/market';
import { normalizeItem } from 'utils/converter';
import { privateRoute } from 'routes';

import { PopupRemint } from './components';
import { CardItem } from 'views/Explore/components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const CollectionRemint = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);

  const [isOpenCreate, setIsOpenCreate] = React.useState(false);

  const { data: collection } = useQuery(['marketService.getSubCollection', { id }], ({ queryKey }) =>
    marketService.getSubCollection({ ...queryKey[1] }),
  );
  const { data, refetch } = useQuery(['marketService.fetchSubCollectionPendings', { id }], ({ queryKey }) =>
    marketService.fetchSubCollectionPendings({ ...queryKey[1] }),
  );
  const dataList = (data.nfts ?? []).sort((a, b) => a.edition - b.edition);
  const firstItem = normalizeItem(dataList[0] ?? {});

  const handleClickCreate = () => {
    if (chain.chainId !== window.ethereum?.chainId) {
      coreuiAction.updateNetwork({ isOpenSwitch: true });
      return;
    }
    setIsOpenCreate(true);
  };

  return (
    <Container maxWidth='md'>
      <Paper className='p-24' elevation={0}>
        <Typography variant='h4' gutterBottom>
          {t('Remint NFT in collection')} {collection.name}
        </Typography>
        <Row gutter={24}>
          <Col sm={16}>
            <FormGroup>
              <Typography variant='h6' gutterBottom>
                {t('Information')}
              </Typography>
              <TextField InputProps={{ readOnly: true }} label={t('Name')} value={firstItem.name ?? ''} />

              <TextField
                multiline
                InputProps={{ readOnly: true }}
                label={t('Description')}
                value={firstItem.description ?? ''}
              />

              <Row gutter={24}>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label={t('Category')}
                    value={firstItem.category?.name ?? ''}
                  />
                </Col>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label={t('Sub Category')}
                    value={firstItem.subCategory?.name ?? ''}
                  />
                </Col>
              </Row>

              <Typography variant='overline' style={{ margin: '28px 0px 24px' }}>
                {t('Number of remaining items you need to remint')}
                {': '}
                <Typography component='span' variant='overline' color='secondary'>
                  {dataList.length}
                </Typography>
              </Typography>

              <Row gutter={24}>
                <Col>
                  <Link to={privateRoute.collectionView.url(id)}>
                    <Button
                      fullWidth
                      variant='outlined'
                      size='large'
                      startIcon={<ArrowBackIcon />}
                      style={{ height: 52 }}
                    >
                      {t('Back')}
                    </Button>
                  </Link>
                </Col>
                <Col flex={1}>
                  <ColorButton
                    fullWidth
                    size='large'
                    disabled={dataList.length === 0}
                    onClick={handleClickCreate}
                    style={{ height: 52 }}
                  >
                    {t('Create')}
                  </ColorButton>
                </Col>
              </Row>

              <Dialog open={isOpenCreate} maxWidth='xs'>
                <PopupRemint
                  item={firstItem}
                  onSuccess={() => {
                    setIsOpenCreate(false);
                    refetch();
                  }}
                />
              </Dialog>
            </FormGroup>
          </Col>
          <Col sm={8}>
            <Hidden xsDown>
              <Box style={{ position: 'sticky', top: 60 + 24 }}>
                <Typography variant='h6' gutterBottom>
                  {t('Preview')}
                </Typography>
                <CardItem.Preview {...firstItem} />
              </Box>
            </Hidden>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};

export default CollectionRemint;
