import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { ColorButton, Loading } from 'components';
import { color } from 'utils/constants';

const PopupConfirm = ({ content, onConfirm, onSuccess, onClose }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickConfirm = async () => {
    setIsLoading(true);
    onConfirm()
      .then(() => {
        onSuccess();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Typography variant='h5' className='mb-20'>
        {t('Confirmation')}
      </Typography>
      <Typography className='mb-40'>{content}</Typography>
      <Box className='Button-Line justify-content-center'>
        <ColorButton hexColor={color.grey} style={{ width: 80 }} disabled={isLoading} onClick={onClose}>
          {t('No')}
        </ColorButton>
        <ColorButton
          style={{ width: 80 }}
          disabled={isLoading}
          startIcon={<Loading visible={isLoading} />}
          onClick={handleClickConfirm}
        >
          {t('Yes')}
        </ColorButton>
      </Box>
    </>
  );
};

export default PopupConfirm;
