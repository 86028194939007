import { AboutUs } from 'views/AboutUs';
import { CookieStatement, PrivacyPolicy, TermsOfUse } from 'views/Footer';
import { Explore, ExploreAuction } from 'views/Explore';
import { ExploreSearch } from 'views/ExploreSearch';
import { ExploreView } from 'views/ExploreView';
import { Create, CreateType, CreateSale } from 'views/Create';
import { Profile, ProfileUpdate } from 'views/Profile';
import { TradeHistory } from 'views/TradeHistory';
import { CollectionCreate } from 'views/Collection';
import { CollectionView } from 'views/CollectionView';
import { CollectionRemint } from 'views/CollectionRemint';

const privateRoute = {
  home: {
    path: '/explore',
    component: Explore,
  },
  aboutUs: {
    path: '/about',
    component: AboutUs,
  },
  privaryPolicy: {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  termsOfUse: {
    path: '/terms-of-use',
    component: TermsOfUse,
  },
  cookieStatement: {
    path: '/cookie-statement',
    component: CookieStatement,
  },

  explore: {
    path: '/explore',
    component: Explore,
  },
  exploreAuction: {
    path: '/explore/auction',
    component: ExploreAuction,
  },
  search: {
    path: '/explore/search',
    component: ExploreSearch,
  },
  exploreView: {
    path: '/explore/:id',
    url: (id) => `/explore/${id}`,
    component: ExploreView,
  },

  create: {
    path: '/create/:type',
    url: (type) => `/create/${type}`,
    component: Create,
    requiredLogin: true,
  },
  createType: {
    path: '/create',
    component: CreateType,
    requiredLogin: true,
  },
  createSale: {
    path: '/create/:id/sale',
    url: (id) => `/create/${id}/sale`,
    component: CreateSale,
    requiredLogin: true,
  },

  artist: {
    path: '/artist/:id',
    url: (id) => `/artist/${id}`,
    component: Profile,
  },
  profile: {
    path: '/profile',
    component: Profile,
    requiredLogin: true,
  },
  profileUpdate: {
    path: '/profile/update',
    component: ProfileUpdate,
    requiredLogin: true,
  },
  tradeHistory: {
    path: '/trade-history',
    component: TradeHistory,
    requiredLogin: true,
  },

  collectionCreate: {
    path: '/collection/create',
    component: CollectionCreate,
    requiredLogin: true,
  },
  collectionView: {
    path: '/collection/:id',
    url: (id) => `/collection/${id}`,
    component: CollectionView,
  },
  collectionRemint: {
    path: '/collection/:id/remint',
    url: (id) => `/collection/${id}/remint`,
    component: CollectionRemint,
    requiredLogin: true,
  },
};

export default privateRoute;
