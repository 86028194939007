import { Col, Row } from 'antd';
import { Heading } from './Headings';

import Partner1 from '../partners/BulishUnicorn-logo.svg';
import Partner2 from '../partners/ex.png';
import Partner3 from '../partners/LupaCapital.svg';
import Partner4 from '../partners/Lysithea.svg';
import Partner5 from '../partners/Maven-Capital-Tight-Black.png';
import Partner6 from '../partners/ngc.svg';
import Partner7 from '../partners/OIG_Logo_Gray.png';
import Partner8 from '../partners/Pantera-ICO.png';
import Partner9 from '../partners/sl2.svg';
import Partner10 from '../partners/svc.svg';
import Partner11 from '../partners/Token-suite.png';
import Partner12 from '../partners/twin.png';
import Partner13 from '../partners/Vivian.png';

const Partners = () => {
  return (
    <>
      <Heading align='center'>Partners</Heading>
      <Row className='justify-content-center'>
        {[
          { image: Partner1, width: 100 },
          { image: Partner2, width: 120 },
          { image: Partner3 },
          { image: Partner4 },
          { image: Partner5 },
          { image: Partner6 },
          { image: Partner7, width: 120 },
          { image: Partner8, width: 120 },
          { image: Partner9 },
          { image: Partner10 },
          { image: Partner11, width: 120 },
          { image: Partner12 },
          { image: Partner13 },
        ].map((item, index) => (
          <Col flex='20%' key={index} className='flex-center' style={{ minWidth: 200, padding: 24 }}>
            <img src={item.image} style={{ width: item.width ?? 180 }} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Partners;
