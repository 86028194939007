import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar, Button, Box, Card, CardMedia, Divider, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { CountdownTimer } from 'components';
import { normalizeItem } from 'utils/converter';
import { privateRoute } from 'routes';

import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';

const CardItem = ({ item: apiItem }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ...profile } = useSelector(({ profile }) => profile);

  const item = React.useMemo(() => normalizeItem(apiItem, profile), [apiItem, profile]);

  return (
    <>
      {item.isErc1155 && (
        <>
          <Paper
            variant='outlined'
            className='Card-Container'
            style={{ position: 'absolute', height: 40, bottom: -8, left: 28, right: 28 }}
          />
          <Paper
            variant='outlined'
            className='Card-Container'
            style={{ position: 'absolute', height: 40, bottom: -4, left: 20, right: 20 }}
          />
        </>
      )}

      <Card variant='outlined' className='Card-Container'>
        <CardMedia
          image={item.preview}
          component={Link}
          to={privateRoute.exploreView.url(item.id)}
          style={{ height: 240, position: 'relative' }}
        >
          <Skeleton variant='rect' width='100%' height='100%' />
          {item.isVideo && (
            <Box className={classes.boxVideo + ' flex-center'}>
              <VideocamOutlinedIcon style={{ color: '#fff' }} />
            </Box>
          )}
          {item.isModel && (
            <Box className={classes.boxVideo + ' flex-center'}>
              <ThreeDRotationIcon style={{ color: '#fff' }} />
            </Box>
          )}
        </CardMedia>
        <Divider />

        <Box style={{ padding: '8px 12px 4px' }}>
          <Typography variant='subtitle1' className='text-ellipsis' title={item.name}>
            <Link to={privateRoute.exploreView.url(item.id)}>{item.name}</Link>
          </Typography>
        </Box>
        <Box className='align-items-center' style={{ padding: '4px 12px 8px' }}>
          <Link to={privateRoute.artist.url(item.owner.address)} className='mr-8'>
            <Avatar src={item.owner.avatar} style={{ width: 28, height: 28 }} />
          </Link>
          <Typography variant='body2' color='textSecondary'>
            {item.owner.name}
          </Typography>
        </Box>
        <Divider className='mx-12 my-4' />
        {item.isAuction || item.isFixedPrice ? (
          <Box className='justify-content-between align-items-start' style={{ padding: '8px 12px 12px' }}>
            <Typography variant='subtitle1'>
              {item.saleInfo.highestPrice || item.saleInfo.price} {item.saleInfo.paymentToken.symbol}
            </Typography>
            {item.isAuction && <CountdownTimer endTime={item.saleInfo.endTime} />}
          </Box>
        ) : (
          <Box className='flex-center' style={{ padding: '1.5px 12px 6px' }}>
            {item.isUserOwner ? (
              <Link to={privateRoute.createSale.url(item.id)}>
                <Button>{t('PUT ON MARKET')}</Button>
              </Link>
            ) : (
              <Button disabled>{t('NOT FOR SALE')}</Button>
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

const CardSkeleton = () => {
  return (
    <Card variant='outlined' style={{ borderRadius: 8 }}>
      <CardMedia style={{ height: 240 }}>
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Divider />
      <Typography style={{ padding: '8px 12px 4px' }}>
        <Skeleton />
      </Typography>
      <Typography style={{ padding: '4px 12px 8px' }}>
        <Skeleton />
      </Typography>
      <Box>
        <Skeleton variant='rect' width='100%' height={60.5} />
      </Box>
    </Card>
  );
};

const CardPreview = ({ ...item }) => {
  const classes = useStyles();
  const { payments } = useSelector(({ system }) => system);
  const { ...profile } = useSelector(({ profile }) => profile);

  return (
    <Card variant='outlined' style={{ borderRadius: 8 }}>
      <CardMedia image={item.isImage ? item.premain : item.preview} style={{ height: 240, position: 'relative' }}>
        <Skeleton variant='rect' width='100%' height='100%' animation={false} style={{ backgroundColor: '#aaa1' }} />
        {item.isVideo && (
          <Box className={classes.boxVideo + ' flex-center'}>
            <VideocamOutlinedIcon style={{ color: '#fff' }} />
          </Box>
        )}
        {item.isModel && (
          <Box className={classes.boxVideo + ' flex-center'}>
            <ThreeDRotationIcon style={{ color: '#fff' }} />
          </Box>
        )}
      </CardMedia>
      <Divider />
      <Box style={{ padding: '8px 12px 4px' }}>
        <Typography variant='subtitle1' className='text-ellipsis' title={item.name} style={{ height: 24 }}>
          {item.name}
        </Typography>
      </Box>
      <Box className='align-items-center' style={{ padding: '4px 12px 8px' }}>
        <Avatar src={profile.avatar} style={{ width: 28, height: 28, marginRight: 8 }} />
        <Typography variant='body2' color='textSecondary'>
          {profile.username}
        </Typography>
      </Box>
      <Divider className='mx-12 my-4' />
      {item.isPutOnMarket ? (
        <Box className='justify-content-between align-items-start' style={{ padding: '8px 12px 12px' }}>
          <Typography variant='subtitle1'>
            {item.price ?? 0} {payments.find((next) => next.id === item.paymentTokenID)?.symbol}
          </Typography>
          {item.isAuction && <CountdownTimer endTime={item.endTime.isValid() && item.endTime.unix()} />}
        </Box>
      ) : (
        <Box className='flex-center' style={{ padding: '8px 12px 12px' }}>
          <Box height={24} />
        </Box>
      )}
    </Card>
  );
};

CardItem.Skeleton = CardSkeleton;
CardItem.Preview = CardPreview;

const useStyles = makeStyles((theme) => ({
  boxVideo: {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: '#0007',
    borderRadius: 20,
    padding: '3px 8px',
  },
}));

export default CardItem;
