import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CircularProgress, Box, Button, IconButton, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { ColorButton } from 'components';
import { minterContract, erc721Contract, collectionContract } from 'contracts';
import { marketService } from 'services/market';

import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';

export const STEP = {
  CREATE: 'create',
  APPROVE: 'approve',
  LISTING: 'listing',
};
export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  TRYAGAIN: 'tryagain',
  SUSSESS: 'success',
};

export const Step = ({ step, status, onTryAgain, action, description }) => {
  const { t } = useTranslation();

  const Status = ({ status }) => {
    if (status === STATUS.IDLE) {
      return <RadioButtonUncheckedIcon style={{ fontSize: 32 }} color='disabled' />;
    }
    if (status === STATUS.LOADING || status === STATUS.TRYAGAIN) {
      return <CircularProgress size={28} />;
    }
    if (status === STATUS.ERROR) {
      return <CloseIcon style={{ fontSize: 32 }} color='error' />;
    }
    if (status === STATUS.SUSSESS) {
      return <CheckIcon style={{ fontSize: 32, color: '#89C87B' }} />;
    }
  };

  if (!action) {
    if (step === STEP.CREATE) action = t('Mint item');
    if (step === STEP.APPROVE) action = t('Approve');
    if (step === STEP.LISTING) action = t('Put on market');
  }
  if (!description) {
    if (step === STEP.CREATE) description = t('Send transaction to create NFT');
    if (step === STEP.APPROVE) description = t('Approve to spend assets on your wallet');
    if (step === STEP.LISTING) description = t('Listing on marketplace');
  }

  return (
    <Box className='flex-row mb-40'>
      <Box width={40} height={40} className='flex-center mr-8'>
        <Status status={status} />
      </Box>
      <Box flex={1}>
        <Box className='justify-content-between'>
          <Typography variant='h6'>{action}</Typography>
        </Box>
        <Typography variant='body2' color='textSecondary'>
          {description}
        </Typography>

        {(status === STATUS.ERROR || status === STATUS.TRYAGAIN) && (
          <Button
            variant='outlined'
            size='small'
            disabled={status === STATUS.TRYAGAIN}
            onClick={() => onTryAgain(true)}
            className='mt-12'
          >
            {t('Try again')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const PopupCreate = ({ formValues: values, item = {}, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const { isImage, isErc1155, isCollection, isPutOnMarket, isAuction } = values;

  const [createStatus, setCreateStatus] = React.useState(STATUS.IDLE);
  const [approveStatus, setApproveStatus] = React.useState(STATUS.IDLE);
  const [listingStatus, setListingStatus] = React.useState(STATUS.IDLE);

  const isLoading = [createStatus, approveStatus, listingStatus].includes(STATUS.LOADING);
  const isTryAgain = [createStatus, approveStatus, listingStatus].includes(STATUS.TRYAGAIN);
  const isCreateError = [createStatus].includes(STATUS.ERROR);
  const saved = React.useRef(item);

  const onCreate = async (isTryAgain) => {
    try {
      setCreateStatus(isTryAgain ? STATUS.TRYAGAIN : STATUS.LOADING);
      const formData = new FormData();
      formData.append('images', values.premain);
      if (isImage) {
        formData.append('images', values.premain);
      } else {
        formData.append('images', values.preview);
      }
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('categoryID', values.categoryID);
      formData.append('subCategoryID', values.subCategoryID);
      if (isErc1155) {
        formData.append('amount', values.amount);
        formData.append('nftContractType', 1);
      }
      if (isCollection) {
        formData.append('amount', values.amount);
        formData.append('subCollectionID', values.subCollectionID);
      }

      const newItem = isCollection
        ? await marketService.createItemCollection(formData)
        : await marketService.createItem(formData);

      if (isCollection) {
        await collectionContract(newItem.nftMinterContractAddr)
          .methods.mintBatch(user, newItem.tokenIDs, newItem.tokenURIs, newItem.signature)
          .send({ from: user });
      } else {
        if (isErc1155) {
          await minterContract(newItem.nftMinterContractAddr)
            .methods.mintSporesERC1155(newItem.tokenID, values.amount, newItem.tokenURI, newItem.signature)
            .send({ from: user });
        } else {
          await minterContract(newItem.nftMinterContractAddr)
            .methods.mintSporesERC721(newItem.tokenID, newItem.tokenURI, newItem.signature)
            .send({ from: user });
        }
      }
      Object.assign(saved.current, {
        id: isCollection ? newItem.ids[0] : newItem.id,
        ownershipID: isCollection ? newItem.ownershipIDs[0] : newItem.ownershipID,
      });

      setCreateStatus(STATUS.SUSSESS);
      await onApprove();
    } catch {
      setCreateStatus(STATUS.ERROR);
    }
  };

  const onApprove = async (isTryAgain) => {
    if (isPutOnMarket)
      try {
        const item = await marketService.getItem({ id: saved.current.id });
        Object.assign(saved.current, {
          nftContractAddr: item.nftContractAddr,
          marketContractAddr: item.marketContractAddr,
        });

        setApproveStatus(isTryAgain ? STATUS.TRYAGAIN : STATUS.LOADING);
        const isApprovedForAll = await erc721Contract(saved.current.nftContractAddr)
          .methods.isApprovedForAll(user, saved.current.marketContractAddr)
          .call();

        if (!isApprovedForAll) {
          await erc721Contract(saved.current.nftContractAddr)
            .methods.setApprovalForAll(saved.current.marketContractAddr, true)
            .send({ from: user });
        }
        setApproveStatus(STATUS.SUSSESS);
        await onListing();
      } catch {
        setApproveStatus(STATUS.ERROR);
      }
  };

  const onListing = async (isTryAgain) => {
    if (isPutOnMarket)
      try {
        setListingStatus(isTryAgain ? STATUS.TRYAGAIN : STATUS.LOADING);
        const saleParams = {
          ownershipID: saved.current.ownershipID,
          paymentTokenID: values.paymentTokenID,
          price: values.price,
          startTime: values.startTime?.unix(),
          endTime: values.endTime.unix(),
          saleType: isAuction ? 1 : 0,
          reservedPrice: values.reservedPrice || undefined,
        };
        await marketService.createSale(saleParams);
        setListingStatus(STATUS.SUSSESS);
      } catch {
        setListingStatus(STATUS.ERROR);
      }
  };

  React.useEffect(() => {
    onCreate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={24} wrap={false}>
      <Col flex={1} className='flex-column justify-content-between'>
        <Box>
          <Box className='justify-content-between align-items-center mb-16'>
            <Typography variant='h5'>{t('Follow steps')}</Typography>
            <IconButton onClick={onClose} disabled={isLoading || isTryAgain}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Step step={STEP.CREATE} status={createStatus} onTryAgain={onCreate} />
          {isPutOnMarket && (
            <>
              <Step step={STEP.APPROVE} status={approveStatus} onTryAgain={onApprove} />
              <Step step={STEP.LISTING} status={listingStatus} onTryAgain={onListing} />
            </>
          )}
        </Box>

        <ColorButton
          fullWidth
          disabled={isLoading || isTryAgain || isCreateError}
          onClick={() => onSuccess(saved.current.id)}
          style={{ height: 52 }}
        >
          {isLoading || isTryAgain
            ? t('PROCESSING')
            : isCreateError
            ? t('SOME ERRORS HAVE OCCURRED')
            : t('VIEW DETAIL')}
        </ColorButton>
      </Col>
    </Row>
  );
};

export default PopupCreate;
