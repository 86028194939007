import { useTranslation } from 'react-i18next';
import { Box, Divider, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const TextOverFlow = ({ item = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className='flex-column align-items-start'>
      <Hidden smDown>
        <Box height={80} />
      </Hidden>
      <Typography variant='h5' className={classes.header}>
        {t('TOP ITEMS')}
      </Typography>
      <Divider style={{ width: 240, backgroundColor: '#fff', margin: '12px 0px 24px' }} />
      <Typography variant='h2' className={classes.title} gutterBottom>
        {item.name}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#fff',
  },
  title: {
    color: '#fff',
    width: '180%',
    maxWidth: '90vw',
    wordBreak: 'break-word',
    WebkitTextStroke: '1px #333',
  },
}));

export default TextOverFlow;
