import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider, CardMedia, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ColorButton } from 'components';
import { Col, Row } from 'antd';
import { color } from 'utils/constants';
import { formatPrice } from 'utils/common';
import { InfoBox } from './CardTransaction';

import CloseIcon from '@material-ui/icons/Close';

const InfoLine = ({ label, value, ...props }) => (
  <Box className='justify-content-between my-12'>
    <Typography color='textSecondary' {...props}>
      {label}
    </Typography>
    <Typography variant='subtitle1' color='textSecondary' {...props}>
      {value}
    </Typography>
  </Box>
);

const PopupTransaction = ({ item, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Box className='justify-content-between align-items-center mb-12'>
        <Typography variant='h5'>{t('Details')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.container}>
        <Row gutter={24}>
          <Col md={10} xs={24}>
            <CardMedia image={item.preview} className={classes.media} />
          </Col>
          <Col md={14}>
            <InfoBox item={item} titleSize={25} contractAddress />
          </Col>
        </Row>
      </Box>

      <Box>
        <InfoLine label={t('Breakdown')} value={t('Amount')} variant='overline' color='textPrimary' />
        <InfoLine label={t('Sale Amount')} value={`${formatPrice(item.buyedPrice)} ${item.paymentToken.symbol}`} />
        <InfoLine label={t('Royalty Fee')} value={`${formatPrice(item.royaltyFee)} ${item.paymentToken.symbol}`} />
        <InfoLine label={t('Spores Fee')} value={`${formatPrice(item.serviceFee)} ${item.paymentToken.symbol}`} />
        <Divider />
        <InfoLine
          label={t('Received')}
          value={`${formatPrice(item.totalReceived)} ${item.paymentToken.symbol}`}
          variant='h6'
          color='textPrimary'
        />
      </Box>

      <Box className='justify-content-end mt-40'>
        <ColorButton style={{ width: 120 }} onClick={onClose}>
          {t('Cancel')}
        </ColorButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 8,
    padding: 24,
    backgroundColor: color.grey,
    marginBottom: 24,
  },
  media: {
    height: '100%',
    borderRadius: 4,
    marginBottom: 12,
  },
}));

export default PopupTransaction;
