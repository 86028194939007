import React from 'react';
import { useQuery } from 'react-query';
import { Box, Container } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { List } from 'antd';
import { marketService } from 'services/market';
import CardTransaction from './CardTransaction';

const TabTradeHistory = ({ isPurchase }) => {
  const [dataSearch, setDataSearch] = React.useState({
    tab: isPurchase ? 'purchases' : 'sales',
    page: 1,
    limit: 6,
  });

  const { data, isFetching } = useQuery(
    ['marketService.fetchUserHistorySales', dataSearch],
    ({ queryKey }) => marketService.fetchUserHistorySales({ ...queryKey[1] }),
    { keepPreviousData: true },
  );
  const { nfts = [], total = 0 } = data;

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      ...search,
    }));
  }, []);

  return (
    <Container maxWidth='lg'>
      {!isFetching ? (
        <List
          grid={{ gutter: [24, 8], column: 2, xs: 1, sm: 1, md: 1 }}
          dataSource={nfts}
          renderItem={(item) => (
            <List.Item>
              <CardTransaction item={item} isPurchase={isPurchase} />
            </List.Item>
          )}
        />
      ) : (
        <List
          grid={{ gutter: [24, 8], column: 2, xs: 1, sm: 1, md: 1 }}
          dataSource={Array.from({ length: dataSearch.limit }).map(() => ({}))}
          renderItem={() => (
            <List.Item>
              <CardTransaction.Skeleton />
            </List.Item>
          )}
        />
      )}
      <Box className='justify-content-center mt-12'>
        <Pagination
          size='large'
          count={Math.ceil(total / dataSearch.limit)}
          page={dataSearch.page}
          onChange={(event, page) => handleChangeSearch({ page })}
        />
      </Box>
    </Container>
  );
};

export default TabTradeHistory;
