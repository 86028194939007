import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import BoxImage from './BoxImage';

const Description = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant='h6' className='mb-16'>
        {t('Description')}
      </Typography>
      <Row gutter={[24, 12]}>
        <Col flex={1}>
          <BoxImage
            image={item.collection?.image}
            label={t('Collection')}
            author={item.collection?.name}
            collection={item.collection}
          />
        </Col>
        <Col flex={3}>
          <BoxImage image={item.owner.avatar} label={t('Owner')} author={item.owner.name} user={item.owner} />
        </Col>
      </Row>
      <Typography color='textSecondary' className='linebreak my-16'>
        {item.description}
      </Typography>
    </Box>
  );
};

export default Description;
