import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Avatar, Button, Container, Box, Typography, Paper, Divider, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { marketService } from 'services/market';
import { normalizeCollection } from 'utils/converter';
import { parse, stringify } from 'query-string';
import { privateRoute } from 'routes';
import { TabCollectionListing } from './components';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const CollectionView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { id } = useParams();
  const { tab, ...query } = parse(location.search);
  const { t } = useTranslation();
  const { ...profile } = useSelector(({ profile }) => profile);

  const { data, isFetching } = useQuery(['marketService.getSubCollection', { id }], ({ queryKey }) =>
    marketService.getSubCollection({ ...queryKey[1] }),
  );
  const collection = React.useMemo(() => normalizeCollection(data, profile), [data, profile]);

  React.useEffect(() => {
    document.title = `${collection.name} - NFT project | Spores`;
    return () => (document.title = `Spores - NFT marketplace`);
  }, [collection]);

  const tabs = [
    { id: 1, code: 'explore', label: t('Explore'), component: <TabCollectionListing /> },
    // { id: 2, code: 'history', label: t('Trading History'), component: <TabCollectionHistory /> },
  ];
  const [activeTab, setActiveTab] = React.useState((tabs.find((item) => item.code === tab) ?? tabs[0]).code);

  const handleChangeTab = (_, nextTab) => {
    setActiveTab(nextTab);
    window.history.replaceState(null, null, '?' + stringify({ ...query, tab: nextTab }));
  };

  return (
    <Container>
      <Box
        height={280}
        className={classes.cover}
        style={collection.id ? { backgroundImage: `url(${collection.cover})` } : {}}
      />
      <Box className='justify-content-center'>
        <Avatar src={collection.id ? collection.image : undefined} className={classes.image} />
      </Box>

      <Box className='flex-column align-items-center mt-12'>
        <Typography variant='h4'>{collection.name}</Typography>
        <Typography variant='h6' color='textSecondary' gutterBottom>
          {collection.symbol || '-'}
        </Typography>
        <Box className='flex-row align-items-center mb-24'>
          <Typography color='textSecondary' className='mr-8'>
            {t('Created by')} -
          </Typography>
          <Link to={privateRoute.artist.url(collection.creator?.address)} className='flex-row'>
            <Avatar src={collection.creator?.avatar} className={classes.avatar} />
            <Typography>{collection.creator?.name}</Typography>
          </Link>
        </Box>

        <Paper className={classes.board + ' flex-row align-items-center mb-24'}>
          <Box flex={1} className='flex-column justify-content-center align-items-center'>
            <Typography variant='h6'>{collection.maxEdition ?? 0}</Typography>
            <Typography color='textSecondary'>{t('Items')}</Typography>
          </Box>
          <Divider orientation='vertical' style={{ height: 40 }} />
          <Box flex={1} className='flex-column justify-content-center align-items-center'>
            <Typography variant='h6'>{collection.numberOfItems ?? 0}</Typography>
            <Typography color='textSecondary'>{t('Available')}</Typography>
          </Box>
          <Divider orientation='vertical' style={{ height: 40 }} />
          <Box flex={1} className='flex-column justify-content-center align-items-center'>
            <Typography variant='h6'>{collection.numberOfOwners ?? 0}</Typography>
            <Typography color='textSecondary'>{t('Owners')}</Typography>
          </Box>
        </Paper>

        <Container maxWidth='md'>
          <Typography color='textSecondary' className='linebreak' align='center'>
            {collection.description || '-'}
          </Typography>
          {collection.isUserCreator && collection.isPending && !isFetching && (
            <Paper elevation={0} className='flex-center p-12' style={{ backgroundColor: '#ffab00', marginTop: 24 }}>
              <Typography className='mr-8'>{t('This collection is missing items, you need to remint.')}</Typography>
              <Link to={privateRoute.collectionRemint.url(collection.id)}>
                <Button variant='outlined' size='small' endIcon={<ArrowForwardIcon />}>
                  {t('Remint now')}
                </Button>
              </Link>
            </Paper>
          )}
        </Container>
      </Box>

      <Box mt={6}>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <Box key={tab.id} hidden={tab.code !== activeTab} py={3}>
            {tab.component}
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  cover: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: 16,
  },
  image: {
    width: 180,
    height: 180,
    border: '4px solid #fff',
    backgroundColor: '#fff',
    marginTop: -120,
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  board: {
    width: 480,
    height: 80,
    borderRadius: 40,
  },
}));

export default CollectionView;
