import { QueryClient } from 'react-query';
import { Spin, notification } from 'antd';
import { CircularProgress } from '@material-ui/core';

export const run = () => {
  Spin.setDefaultIndicator(<CircularProgress />);

  notification.config({
    placement: 'topRight',
    top: 72,
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,
      placeholderData: {},
      refetchOnMount: 'always',
      retry: 0,
    },
  },
});
