import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Container, Typography, Paper, FormGroup, TextField, Box } from '@material-ui/core';
import { ColorButton, Loading } from 'components';
import { profileAction } from 'actions/profile';
import { userService } from 'services/user';
import { validator } from 'utils/validator';
import { privateRoute } from 'routes';
import { UserAvatar } from 'views/Profile/components';

const ProfileUpdate = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { ...profile } = useSelector(({ profile }) => profile);

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickUpdate = () => {
    handleSubmit(({ username, bio, email }) => {
      const formData = new FormData();
      formData.append('username', username.trim());
      formData.append('bio', bio.trim());
      formData.append('email', email.trim());

      setIsLoading(true);
      userService
        .updateProfile(formData)
        .then(async () => {
          await profileAction.fetchProfile();
          history.push(privateRoute.profile.path);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  };

  return (
    <Container maxWidth='sm'>
      <Paper className='p-24' elevation={1}>
        <Box className='flex-row align-items-center mb-24'>
          <UserAvatar size={80} border={2} />
          <Box ml={2}>
            <Typography variant='h6'>{profile.username}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {profile.id}
            </Typography>
          </Box>
        </Box>

        <Typography variant='h4'>{t('Update Profile')}</Typography>
        <Typography color='textSecondary' className='mb-24'>
          {t('People visiting your profile will see the following info')}
        </Typography>

        <FormGroup>
          <Controller
            name='username'
            defaultValue={profile.username}
            control={control}
            rules={{
              validate: {
                required: (value) => value.trim() !== '' || t('Name cannot be empty'),
                minLength: (value) => value.trim().length >= 5 || t('Name is at least 5 characters'),
                maxLength: (value) => value.trim().length <= 40 || t('Name is at most 40 characters'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} required label={t('Name')} error={invalid} helperText={error?.message} />
            )}
          />

          <Controller
            name='bio'
            defaultValue={profile.bio}
            control={control}
            rules={{
              validate: {
                maxLength: (value) => value.trim().length <= 160 || t('Biography is at most 160 characters'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                multiline
                rows={5}
                label={t('Biography')}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name='email'
            defaultValue={profile.email}
            control={control}
            rules={{
              validate: {
                pattern: (value) =>
                  value.trim() === '' || validator.isValidEmail(value) || t('Please provide a valid email address'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} label={t('Email')} error={invalid} helperText={error?.message} />
            )}
          />
        </FormGroup>

        <Box className='Button-Line justify-content-end'>
          <Link to={privateRoute.profile.path}>
            <ColorButton variant='outlined' color='default'>
              {t('Cancel')}
            </ColorButton>
          </Link>

          <ColorButton
            variant='contained'
            disabled={isLoading}
            startIcon={<Loading visible={isLoading} />}
            onClick={handleClickUpdate}
            style={{ width: 120 }}
          >
            {t('Update')}
          </ColorButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProfileUpdate;
