import { client } from './axios';

const upload = (file, headers) =>
  client.get(`/v1/nft/medias/presign-upload`).then(({ url }) =>
    fetch(url, {
      method: 'PUT',
      headers: {
        'X-Amz-Acl': 'public-read',
        ...headers,
      },
      body: file,
    }).then(() => {
      const { origin, pathname } = new URL(url);
      return origin + pathname;
    }),
  );

export const fileService = {
  upload,
};
