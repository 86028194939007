import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Controller } from 'react-hook-form';
import { Avatar, Box, Button, Typography } from '@material-ui/core';
import { Col, Row } from 'antd';
import { PerfectScrollbar } from 'components';
import { marketService } from 'services/market';
import { normalizeCollection } from 'utils/converter';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';

import AddCircleIcon from '@material-ui/icons/AddCircle';

const Collection = ({ control }) => {
  const { t } = useTranslation();
  const { id: user } = useSelector(({ profile }) => profile);

  const { data } = useQuery(['marketService.fetchSubCollections', { creatorAddr: user, limit: 0 }], ({ queryKey }) =>
    marketService.fetchSubCollections({ ...queryKey[1] }),
  );
  const subCollections = (data.subCollections ?? []).map(normalizeCollection);

  return (
    <Controller
      name='subCollectionID'
      control={control}
      rules={{ required: true }}
      render={({ field: { value, onChange }, fieldState: { invalid } }) => (
        <>
          {invalid && (
            <Typography variant='body2' color='error' gutterBottom>
              {t('Collection is required')}
            </Typography>
          )}

          <PerfectScrollbar style={{ maxHeight: 360, marginRight: -16, paddingRight: 16 }}>
            <Row gutter={[24, 12]}>
              {subCollections.length === 0 && (
                <Col span={8}>
                  <Link to={privateRoute.collectionCreate.path}>
                    <Button
                      fullWidth
                      variant='outlined'
                      style={{ padding: 12, borderWidth: 2, borderRadius: 8, borderStyle: 'dashed' }}
                    >
                      <Box className='flex-column align-items-center'>
                        <Box
                          className='flex-center'
                          style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: color.primaryLight }}
                        >
                          <AddCircleIcon color='primary' />
                        </Box>
                        <Typography variant='subtitle1'>{t('Create')}</Typography>
                        <Typography variant='body2' color='textSecondary'>
                          {t('ERC-721')}
                        </Typography>
                      </Box>
                    </Button>
                  </Link>
                </Col>
              )}

              {subCollections.map((item) => {
                const checked = item.id === value;
                return (
                  <Col span={8} key={item.id}>
                    <Button
                      fullWidth
                      variant='outlined'
                      style={{
                        padding: 12,
                        borderWidth: 2,
                        borderRadius: 8,
                        borderColor: checked ? color.primary : color.alice,
                        backgroundColor: checked ? color.primaryLight : color.white,
                      }}
                      onClick={() => onChange(item.id)}
                    >
                      <Box className='flex-column align-items-center' overflow='hidden'>
                        <Avatar src={item.image} />
                        <Typography
                          variant='subtitle1'
                          color={checked ? 'textPrimary' : 'textSecondary'}
                          className='text-ellipsis'
                          title={item.name || '-'}
                        >
                          {item.name || '-'}
                        </Typography>
                        <Typography variant='body2' color='textSecondary'>
                          {item.symbol || '-'}
                        </Typography>
                      </Box>
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </PerfectScrollbar>
        </>
      )}
    />
  );
};

export default Collection;
