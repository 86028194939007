import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInfiniteQuery } from 'react-query';
import { Box, Link as NavLink, Typography, makeStyles } from '@material-ui/core';
import { InfiniteScroll, PerfectScrollbar } from 'components';
import { marketService } from 'services/market';
import { MMDDYYYY__HHMM } from 'utils/constants';
import { moment } from 'utils/common';
import { privateRoute } from 'routes';
import BoxImage from './BoxImage';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const ACTION_TYPES = {
  0: 'Minted',
  1: 'Listed',
  2: 'Unlisted',
  3: 'Bid',
  4: 'Purchased',
  5: 'Transferred',
};

const TabHistorySales = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const { chain } = useSelector(({ coreui }) => coreui);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['marketService.fetchHistorySales', { id, limit: 10 }],
    ({ queryKey, pageParam: start }) => marketService.fetchHistorySales({ ...queryKey[1], start }),
    {
      getNextPageParam: ({ count, nftEventHistory }) => {
        if (count !== 10) return undefined;
        return nftEventHistory[count - 1].createdAt;
      },
    },
  );
  const dataList = (data.pages ?? []).reduce((previous, data) => previous.concat(data.nftEventHistory), []);

  return (
    <PerfectScrollbar style={{ maxHeight: 68 * 4, paddingRight: 12 }}>
      <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage} useWindow={false}>
        {dataList.map((item) => (
          <Box key={item.id} className={classes.wrapper}>
            <BoxImage
              reverse
              image={item.byUser.avatar}
              label={
                <div className='flex-row'>
                  {t('by')}
                  <Typography
                    variant='subtitle2'
                    component={Link}
                    to={privateRoute.artist.url(item.byUser.address)}
                    className={classes.link}
                  >
                    {t(item.byUser.name)}
                  </Typography>
                  {t('at')} {moment(item.updatedAt * 1000).format(MMDDYYYY__HHMM)}
                  {Boolean(item.txHash) && (
                    <NavLink href={chain.viewTrans(item.txHash)} className={classes.link + ' align-items-end'}>
                      <OpenInNewIcon style={{ fontSize: 18 }} />
                    </NavLink>
                  )}
                </div>
              }
              author={
                <div className='justify-content-between'>
                  <span>{t(ACTION_TYPES[item.eventType])}</span>
                  {item.price > 0 && <span>{`${item.price} ${item.symbol}`}</span>}
                </div>
              }
              size={44}
              user={{ address: item.byUser.address }}
            />
          </Box>
        ))}
      </InfiniteScroll>
    </PerfectScrollbar>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 12,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  link: {
    color: 'inherit',
    margin: '0px 4px',
  },
}));

export default TabHistorySales;
